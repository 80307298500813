@import url('https://fonts.googleapis.com/css?family=EB+Garamond:400,500,600,700|Noto+Serif+SC:400,500,600,700&display=swap');

$ch-family: 'Noto Serif SC', Meiryo,"STXihei", HEITI TC, "HEITI TC", "黑體-繁", "微軟正黑體", "黑體", sans-serif;
$content-family: Arial, "STXihei", HEITI TC, "HEITI TC", "黑體-繁", "微軟正黑體", "黑體", sans-serif;
$en-family: 'EB Garamond', serif;


html,body{
	-webkit-text-size-adjust: 100%;   // for mobile fontsize
}

a{
	text-decoration: none;
	color: inherit;
	img{border: 0;}
}

:focus{
	outline: 0;
}

button{
	background-color: transparent;
	border: 0;
}

*{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

img{
	vertical-align: middle; //remove bottom space
	max-width: 100%;
	// max-height: 100%;
}

/* override the default IOS styles */
input, textarea{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}


/*

 \
 _\,,
"-=\~     _
   \\~___( ~
  _|/---\\_
  \        \	base.scss end

*/
