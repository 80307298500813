@import 'foundation';
@import "reset";
@import "base";
@import "public";
// --------------- custom faoundation settings here --------------------
$global-margin: 20px;
$global-padding: 20px;
$prototype-spacing-breakpoints: true;
$prototype-spacers-count: 8;
$breakpoint-classes: (small medium large xlarge xxlarge);
$breakpoints: (
	small: 0px,
	medium: 640px,
	large: 1025px,
	xlarge: 1200px,
	xxlarge: 1700px,
);
@include foundation-xy-grid-classes;
@include foundation-prototype-spacing;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-text-alignment;
$blue: #1f2c5c;
@keyframes reel{
	100%{
		background-position: 0 -144px;
	}
}
@mixin m-index-hover() {
	&:hover{
		.reel{
			opacity: 1;
			animation: reel 0.3s steps(2) both;
		}
	}
	&.active{
		.reel{opacity: 0;}
		.pic,
		.mobile-goto,
		.mobile-close{opacity: 1;}
		.article-area{
			opacity: 1;
			pointer-events: all;
		}
	}
	&.invisible{
		pointer-events: none;
	}
	.reel{
		z-index: 9;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		// cursor: pointer;
		cursor: url(../images/cursor-pointer.svg), auto;
		opacity: 0;
		transition: all .3s;
		width: 94px;
		height: 72px;
		background: url(../images/reel-sp.png) 0 0/100% no-repeat;
		@include breakpoint(medium down){
			opacity: 1;
			animation: reel 0.3s steps(2) both;
		};
	}
	.pic{
		opacity: 0;
		transition: all .5s;
	}
	.article-area{
		position: absolute;
		height: 193px;
		opacity: 0;
		transition: all .5s;
		pointer-events: none;
		@include breakpoint(medium down){
			height: 150px;
		}
		.close{
			position: absolute;
			top: -63px;
			right: 0;
			width: 46px;
			height: 46px;
			cursor: pointer;
			@include breakpoint(medium down){
				display: none;
			}
			&:before, &:after{
				content: '';
				width: 100%;
				height: 1px;
				background-color: #fff;
				position: absolute;
				top: 22px;
				left: 0;
				transform: rotate(45deg);
			}
			&:after{
				transform: rotate(-45deg);
			}
		}
		.btn-area{
			background-color: $blue;
			border-radius: 200px;
			border: 2px solid #fff;
			padding: 34px 10px;
			writing-mode: vertical-lr;
			margin-right: 50px;
			@include breakpoint(medium down){
				display: none;
			}
			.en{
				font-family: $content-family;
				font-weight: 700;
				font-size: 12px;
				letter-spacing: 1px;
				line-height: 1.2;
				color: #fff;
				margin-right: 6px;
			}
			.ch{
				font-family: $ch-family;
				font-weight: 600;
				font-size: 20px;
				letter-spacing: 3px;
				color: #fff;
				position: relative;
				top: -3px;
			}
		}
		.content{
			font-family: $content-family;
			font-size: 14px;
			letter-spacing: 1px;
			line-height: 43px;
			color: #fff;
			writing-mode: tb;
			text-orientation: upright;
			background: linear-gradient(to left, transparent 42px, #fff 42px) 1px 0px/43px 43px repeat;
		}
		.mobile-title-area{
			margin-left: 11px;
		}
	}
	.mobile-goto{
		position: absolute;
		opacity: 0;
		transition: all .5s;
		.text{
			font-family: $content-family;
			font-size: 16px;
			letter-spacing: 3px;
			color: #fff;
		}
		.deco{}
	}
	.mobile-close{
		position: absolute;
		width: 46px;
		height: 46px;
		cursor: pointer;
		opacity: 0;
		transition: all .5s;
		&:before, &:after{
			content: '';
			width: 100%;
			height: 1px;
			background-color: #fff;
			position: absolute;
			top: 22px;
			left: 0;
			transform: rotate(45deg);
		}
		&:after{
			transform: rotate(-45deg);
		}
	}
}
@mixin m-rd-head{
	.ch{
		font-family: $ch-family;
		font-weight: 600;
		font-size: 36px;
		letter-spacing: 6px;
		color: $blue;
		margin-bottom: 8px;
		@include breakpoint(xlarge down){
			font-size: 25px;
			letter-spacing: 4px;
		};
		@include breakpoint(medium down){
			font-size: 20px;
		};
	}
	.en{
		font-family: $en-family;
		font-weight: 700;
		font-size: 26px;
		letter-spacing: 3px;
		color: $blue;
		@include breakpoint(xlarge down){
			font-size: 18px;
			letter-spacing: 2px;
		};
		@include breakpoint(medium down){
			font-size: 14px;
		};
	}
}
.alltemp{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: -1;
    opacity: 0.3;
}
.m-banner{
	height: 334px;
	position: relative;
	margin-top: 68px;
	@include breakpoint(xlarge down){
		height: 224px;
		margin-top: 65px;
	};
	@include breakpoint(medium down){
		height: 200px;
		margin-top: 72px;
	};
	&.traffic-custom{
		height: 810px;
		@include breakpoint(xlarge down){
			height: 540px;
		}
		@include breakpoint(medium down){
			height: 200px;
		}
	}
}
body{
	background-color: #efefef;
	overflow-x: hidden;
	--mobileSpace: 20px;
}
#mobile-wrapper{
	overflow-x: hidden;
}
.enter-outWrap{
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
}
.enterWrap{
	width: 1920px;
	height: 969px;
	position: absolute;
	top: 0;
	left: 0;
	transform-origin: left top;
	@include breakpoint(medium down){
		transform-origin: -70% top;
	}
	.gradient-deco{
		background: linear-gradient(to top, #c9b10f, #fff);
		position: absolute;
		width: 100%;
		height: 600px;
		left: 0;
		// bottom: 0;
		bottom: -350px;
		display: none;
	}
	.cloud-1{
		position: absolute;
		top: 10px;
		left: 150px;
		display: none;
	}
	.cloud-2{
		position: absolute;
		top: 180px;
	    left: 420px;
	    display: none;
	}
	.cloud-3{
		position: absolute;
		top: 270px;
		left: 0;
		display: none;
	}
	.cloud-4{
		position: absolute;
		top: 100px;
		right: 540px;
		display: none;
	}
	.cloud-5{
		position: absolute;
		top: 30px;
		right: 50px;
		display: none;
	}
	.cloud-6{
		position: absolute;
		top: 290px;
	    right: 40px;
	    display: none;
	}
	// .sun-area{
	// 	position: absolute;
	// 	// top: 301px;
	// 	top: 761px;
	// 	left: 650px;
	// 	width: 559px;
	// 	height: 564px;
	// 	z-index: 2;
	// 	@include breakpoint(medium down){
	// 		transform: scale(0.78);
	// 		left: 3%;
	// 	};
	// 	.sun{
	// 		position: absolute;
	// 		top: 0;
	// 		left: 0;
	// 		display: none;
	// 	}
	// 	.logo{
	// 		position: absolute;
	// 		top: 81px;
	// 		left: 136px;
	// 		width: 300px;
	// 		display: none;
	// 		@include breakpoint(medium down){
	// 			top: 100px;
	// 		};
	// 	}
	// }
	.wave-level-1{
		position: absolute;
	    left: 0;
	    // bottom: -156px;
	    bottom: -407px;
	    width: 100%;
	    height: 407px;
	    background: url(../images/wave-level-1.png) -42px 0px repeat-x;
	    z-index: 9;
	}
	.wave-level-2{
		position: absolute;
	    left: 0;
	    // bottom: -156px;
	    bottom: -418px;
	    width: 100%;
	    height: 418px;
	    background: url(../images/wave-level-2.png) -307px 0px repeat-x;
	    z-index: 6;
	}
	.wave-level-3{
		position: absolute;
	    left: 0;
	    // bottom: 40px;
	    bottom: -315px;
	    width: 100%;
	    height: 315px;
	    background: url(../images/wave-level-3.png) 420px 0px repeat-x;
	    z-index: 3;
	}
	.wave-level-4{
		position: absolute;
	    left: 0;
	    // bottom: 115px;
	    bottom: -315px;
	    width: 100%;
	    height: 315px;
	    background: url(../images/wave-level-4.png) -70px 0px repeat-x;
	    z-index: 1;
	}
	.wave-level-5{
		position: absolute;
	    left: 0;
	    // bottom: 155px;
	    bottom: -350px;
	    width: 100%;
	    height: 350px;
	    background: url(../images/wave-level-5.png) -510px 0px repeat-x;
	}
	.wave-1{
		position: absolute;
		left: 0;
		bottom: -40px;
		z-index: 9;
	}
	.wave-2{
		position: absolute;
		left: 720px;
		bottom: -40px;
		z-index: 9;
	}
	.wave-3{
		position: absolute;
		right: 0;
		bottom: -40px;
		z-index: 9;
	}
	.wave-4{
		position: absolute;
		left: 0;
		bottom: -10px;
		z-index: 6;
	}
	.wave-5{
		position: absolute;
		left: 390px;
		bottom: -40px;
		z-index: 6;
	}
	.wave-6{
		position: absolute;
		bottom: -50px;
		right: 140px;
		z-index: 6;
	}
	.wave-7{
		position: absolute;
		left: 410px;
		bottom: 59px;
		z-index: 3;
	}
	.wave-8{
		position: absolute;
		bottom: 82px;
		right: 60px;
		z-index: 3;
	}
	.wave-9{
		position: absolute;
		left: 0;
		bottom: 154px;
		z-index: 1;
	}
	.wave-10{
		position: absolute;
		bottom: 126px;
		z-index: 1;
		right: 0;
	}
	.wave-11{
		position: absolute;
		left: 0;
		bottom: 210px;
	}
	.wave-12{
		position: absolute;
		bottom: 173px;
		right: 70px;
	}
	.spray-1{
		position: absolute;
		left: 503px;
		// bottom: -46px;
		bottom: -240px;
		z-index: 8;
		display: none;
	}
	.spray-2{
		position: absolute;
		right: 333px;
		// bottom: -28px;
		bottom: -222px;
		z-index: 8;
		display: none;
	}
	.spray-3{
		position: absolute;
		left: 234px;
		// bottom: 166px;
		bottom: -78px;
		z-index: 5;
		display: none;
	}
	.spray-4{
		position: absolute;
		left: 418px;
		// bottom: 197px;
		bottom: -47px;
		z-index: 2;
		display: none;
	}
	.spray-5{
		position: absolute;
		right: 585px;
		// bottom: 165px;
		bottom: -79px;
		z-index: 2;
		display: none;
	}
	.spray-6{
		position: absolute;
		right: 207px;
		// bottom: 256px;
		bottom: 62px;
		display: none;
	}
	.spray-7{
		position: absolute;
		left: 40px;
		// bottom: 347px;
		bottom: 153px;
		display: none;
	}
	.spray-8{
		position: absolute;
		right: 73px;
		// bottom: 326px;
		bottom: 132px;
		display: none;
	}
	.spray-9{
		position: absolute;
		right: 0;
		// bottom: 368px;
		bottom: 174px;
		display: none;
	}
}
.enter-logo{
	z-index: 4;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	margin-top: -90px;
	display: none;
	@include breakpoint(xlarge down){
		width: 310px;
		margin-top: -70px;
	}
	@include breakpoint(medium down){
		width: 220px;
	}
}
.topmenuWrap{
	z-index: 49;
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	padding: 15px 0;
	@include breakpoint(xlarge down){
		padding: 12px 0;
	};
	&:after{
		content: '';
		z-index: -1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 138px;
		background: url(../images/topmenu-bg.svg) 0 0/100% 100% no-repeat;
		@include breakpoint(xlarge down){
			height: 112px;
			background: url(../images/xlarge-topmenu-bg.svg) 0 0/100% 100% no-repeat;
		};
	}
	.topmenuList{
		margin: 0 40px;
		a{
			font-family: $content-family;
			font-size: 13px;
			letter-spacing: 1px;
			color: #fff;
			@include mr(70px);
			@include breakpoint(xlarge down){
				@include mr(30px);
			};
		}
	}
	.logo{
		margin: 0 100px;
		@include breakpoint(xlarge down){
			margin: 0 25px;
			width: 140px;
		};
	}
	.shareList{
		a{
			transition: all .3s;
			@include mr(21px);
			&:hover{opacity: .6;}
		}
	}
}
.reservation{
	position: fixed;
	left: 5px;
	bottom: 5px;
	z-index: 49;
	text-align: center;
	width: 102px;
	height: 534px;
	padding: 74px 0;
	background: url(../images/reservation-bg.svg) center center/cover no-repeat;
	@include breakpoint(xlarge down){
		width: 83px;
	    height: 434px;
	    padding: 52px 0;
	};
	.icon-1{
		margin-bottom: 20px;
		@include breakpoint(xlarge down){
			margin-bottom: 15px;
			img{width: 22px;}
		};
	}
	.text-1{
		margin-bottom: 20px;
		@include breakpoint(xlarge down){
			margin-bottom: 15px;
			img{width: 34px;}
		};
	}
	.line{
		width: 42px;
		height: 1px;
		background-color: #fff;
		margin: 0 auto 20px;
		@include breakpoint(xlarge down){
			width: 34px;
			margin-bottom: 14px;
		};
	}
	.text-2{
		margin-bottom: 20px;
		@include breakpoint(xlarge down){
			margin-bottom: 14px;
			img{width: 34px;}
		};
	}
	.icon-2{
		margin-bottom: 20px;
		@include breakpoint(xlarge down){
			margin-bottom: 13px;
			img{width: 27px;}
		};
	}
	.icon-3{
		@include breakpoint(xlarge down){
			img{width: 26px;}
		};
	}
}
#scene{
	width: 100%;
	height: 100vh;
	overflow: hidden;
	// cursor: grab;
	cursor: url(../images/cursor.svg), auto;
	@include breakpoint(medium down){
		height: 108vh;
		padding-bottom: 8vh;
	}
}
#stage{
	will-change: transform;
	.dialog-container{
		position: relative;
		.bigbg{
			max-width: initial;
			width: auto;
			height: 100vh;
		}
	}
}
.hover-innerWrap, .log-innerWrap{
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
}
.hover-container{
	width: 7175px;
	height: 969px;
	position: absolute;
	top: 0;
	left: 0;
	transform-origin: left top;
	.black-bg{
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(#000, .75);
		cursor: pointer;
		display: none;
	}
	div[class|=hover]{
		@include m-index-hover;
	}
	.hover-1{
		position: absolute;
		top: 174px;
		left: 304px;
		.reel{
			transform: translate(-309px, -96px) !important;
		}
		.pic{}
		.article-area{
			right: -230px;
			bottom: 126px;
			@include breakpoint(medium down){
				right: auto;
			    bottom: auto;
			    top: -40px;
			    left: -96px;
			}
		}
		.mobile-goto{
			bottom: -25px;
		    left: 365px;
		}
		.mobile-close{
			left: 90px;
			bottom: -30px;
		}
	}
	.hover-2{
		position: absolute;
		top: 307px;
	    left: 1715px;
	    .reel{
	    	transform: translate(66px, -210px) !important;
	    }
		.pic{}
		.article-area{
			left: -214px;
			bottom: 206px;
			@include breakpoint(medium down){
				left: 401px;
			    bottom: auto;
			    top: -173px;
			}
		}
		.mobile-goto{
			bottom: 30px;
		    left: 515px;
		    img{width: 114px;}
		}
		.mobile-close{
			left: 672px;
			bottom: 25px;
		}
	}
	.hover-3{
		position: absolute;
		top: 639px;
		left: 2847px;
		.reel{
		    transform: translate(-253px, -115px) !important;
		}
		.pic{}
		.article-area{
			left: 50px;
		    top: -240px;
		    @include breakpoint(medium down){
		    	left: 91px;
		    	top: -480px;
		    }
		}
		.mobile-goto{
			bottom: 360px;
			left: 380px;
		}
		.mobile-close{
			left: 52px;
		    bottom: 353px;
		}
	}
	.hover-4{
		position: absolute;
		top: 279px;
	    left: 3543px;
	    .reel{
	    	transform: translate(-230px, -29px) !important;
	    }
		.pic{}
		.article-area{
			left: -236px;
		    bottom: 140px;
		    @include breakpoint(medium down){
		    	left: 190px;
		    	bottom: auto;
		    	top: -160px;
		    }
		}
		.mobile-goto{
			bottom: 97px;
			left: 210px;
		}
		.mobile-close{
			left: 594px;
			bottom: 95px;
		}
	}
	.hover-5{
		position: absolute;
		top: 133px;
	    left: 4612px;
	    .reel{
	    	transform: translate(-45px, -129px) !important;
	    }
		.pic{}
		.article-area{
			left: -121px;
		    bottom: -146px;
		    @include breakpoint(medium down){
		    	left: 436px;
		    	bottom: auto;
		    	top: -11px;
		    }
		}
		.mobile-goto{
			bottom: -135px;
			left: 814px;
		}
		.mobile-close{
			left: 484px;
		    bottom: -145px;
		}
	}
	.hover-6{
		position: absolute;
		top: 241px;
		left: 5478px;
		.reel{
			transform: translate(-54px, -129px) !important;
		}
		.pic{}
		.article-area{
			right: -391px;
			bottom: 218px;
			@include breakpoint(medium down){
				right: 81px;
				bottom: auto;
				top: -110px;
			}
		}
		.mobile-goto{
			bottom: -75px;
			left: 354px;
		}
		.mobile-close{
			left: 54px;
			bottom: -83px;
		}
	}
	.hover-7{
		position: absolute;
		top: 80px;
	    left: 5936px;
		.pic{}
		.article-area{
			right: 81px;
		    bottom: -280px;
		    @include breakpoint(medium down){
		    	right: 71px;
		    	bottom: -230px;
		    }
		}
		.mobile-goto{
			bottom: -485px;
		    left: 444px;
		}
		.mobile-close{
			left: 134px;
			bottom: -491px;
		}
	}
	.hover-8{
		position: absolute;
		top: 362px;
		left: 6634px;
		.pic{}
		.article-area{
			left: -297px;
			bottom: 68px;
			@include breakpoint(medium down){
				left: 190px;
			    bottom: auto;
			    top: -212px;
			}
		}
		.mobile-goto{
			bottom: 102px;
			left: 344px;
		}
		.mobile-close{
			left: 10px;
		    bottom: 106px;
		}
	}
	.hover-9{
		position: absolute;
		top: 265px;
		left: 5800px;
		.reel{
			transform: translate(-20px, -70px) !important;
		}
		.pic{}
		.article-area{
			left: -428px;
			bottom: 249px;
			@include breakpoint(medium down){
				left: 120px;
			    bottom: auto;
			    top: -147px;
			}
		}
		.mobile-goto{
			bottom: 50px;
		    left: 544px;
		}
		.mobile-close{
			left: 294px;
		    bottom: 46px;
		}
	}
}
.log-container{
	width: 7175px;
	height: 969px;
	position: absolute;
	top: 0;
	left: 0;
	transform-origin: left top;
	img[class|=log]{
		position: absolute;
		opacity: 0;
		transition: all .5s;
	}
	.log-1{
		right: 6.59%;
		bottom: 8.26%;
	}
	.log-2{
		right: 14.41%;
		bottom: 3.82%;
	}
	.log-3{
		right: 14.94%;
		bottom: 23.32%;
	}
	.log-4{
		right: 23.83%;
		bottom: 10.32%;
	}
	.log-5{
		right: 26.68%;
		bottom: 3.82%;
	}
	.log-6{
		right: 26.62%;
		bottom: 43.86%;
	}
	.log-7{
		right: 37.39%;
		bottom: 9.39%;
	}
	.log-8{
		right: 41.8%;
		bottom: 12.8%;
	}
	.log-9{
		right: 45.51%;
		bottom: 2.06%;
	}
	.log-10{
		right: 53.74%;
		bottom: 11.66%;
	}
	.log-11{
		right: 63.53%;
		bottom: 17.03%;
	}
	.log-12{
		right: 69.6%;
		bottom: 16.41%;
	}
	.log-13{
		right: 72.25%;
		bottom: 12.38%;
	}
	.log-14{
		right: 76.07%;
		bottom: 3.41%;
	}
	.log-15{
		right: 80.04%;
		bottom: 27.04%;
	}
	.log-16{
		right: 83.58%;
		bottom: 26.83%;
	}
	.log-17{
		right: 85.74%;
		bottom: 5.26%;
	}
	.log-18{
		right: 88.85%;
		bottom: 22.08%;
	}
	.log-19{
		right: 91.89%;
	    bottom: 29.31%;
	}
	.log-20{
		right: 93.05%;
		bottom: 58.62%;
	}
	.log-21{
		right: 28.43%;
		bottom: 39.01%;
	}
}
.contactWrap{
	padding-top: 240px;
	padding-bottom: 50px;
	max-width: 1320px;
	margin: 0 auto;
	position: relative;
	@include breakpoint(xlarge down){
		padding: 200px 120px 50px 160px;
	}
	@include breakpoint(medium down){
		padding: 100px var(--mobileSpace) 60px;
	};
	.info-container{
		max-width: 496px;
		@include breakpoint(xlarge down){
			max-width: 346px;
		}
		.head-area{
			margin-bottom: 130px;
			@include breakpoint(xlarge down){
				margin-bottom: 100px;
			}
			@include breakpoint(medium down){
				margin-bottom: 40px;
			};
			.head{
				@extend .m-head;
			}
		}
		.phone{
			font-family: $content-family;
			font-size: 36px;
			letter-spacing: 3px;
			color: $blue;
			margin-bottom: 30px;
			@include breakpoint(xlarge down){
				font-size: 24px;
				letter-spacing: 2px;
			}
			@include breakpoint(medium down){
				margin-bottom: 29px;
			};
			i{
				font-size: 12px;
				letter-spacing: 0;
				margin-right: 15px;
				display: inline-block;
				vertical-align: top;
				position: relative;
				top: 5px;
			}
		}
		// .email{
		// 	font-family: $content-family;
		// 	font-size: 14px;
		// 	letter-spacing: 1px;
		// 	line-height: 1.5;
		// 	color: $blue;
		// 	margin-bottom: 25px;
		// 	@include breakpoint(medium down){
		// 		margin-bottom: 7px;
		// 	};
		// 	i{
		// 		font-size: 12px;
		// 		display: block;
		// 		margin-bottom: 2px;
		// 	}
		// }
		.address{
			font-family: $content-family;
			font-weight: 700;
			font-size: 22px;
			letter-spacing: 1px;
			line-height: 1.5;
			color: $blue;
			@include breakpoint(xlarge down){
				font-size: 15px;
			}
			@include breakpoint(medium down){
				font-size: 14px;
			}
			i{
				font-weight: 400;
				font-size: 12px;
				display: block;
				margin-bottom: 5px;
			}
		}
	}
	.map{
		@include breakpoint(xlarge down){
			max-width: 600px;
		}
		@include breakpoint(medium down){
			margin-top: 23px;
			a{
				display: inline-block;
			    margin: 0 -110px 0 -60px;
			}
		}
	}
	.cloud-1{
		position: absolute;
		top: 33%;
		left: 21.7%;
		@include breakpoint(xlarge down){
			top: 38%;
		    left: 31.7%;
		    width: 91px;
		}
		@include breakpoint(medium down){
			top: auto;
			left: auto;
			bottom: 36px;
			right: 25px;
		};
	}
	.cloud-2{
		position: absolute;
		top: 48.5%;
		right: -116px;
		@include breakpoint(xlarge down){
			top: 47.5%;
			right: 44px;
			width: 91px;
		}
	}
	.cloud-3{
		position: absolute;
		left: -180px;
		bottom: -14px;
		z-index: 1;
		@include breakpoint(xlarge down){
			left: 50px;
		    bottom: 18px;
		    width: 106px;
		}
	}
	.cloud-4{
		position: absolute;
		left: -60px;
		bottom: -14px;
		@include breakpoint(xlarge down){
			left: 123px;
		    bottom: 19px;
		    width: 80px;
		}
	}
	.people{
		position: absolute;
		right: -254px;
		bottom: 82px;
		@include breakpoint(xlarge down){
			right: -31px;
		    bottom: 79px;
		    width: 242px;
		}
	}
}
@keyframes float-left{
	0%{
		transform: translate(0px, 0px);
	}
	100%{
		transform: translate(-30px, 0px);
	}
}
.footerWrap{
	background-color: $blue;
	padding: 55px 134px;
	@include breakpoint(xlarge down){
		padding: 44px 120px;
	};
	@include breakpoint(medium down){
		padding: 40px var(--mobileSpace);
	};
	.footerList{
		max-width: 400px;
		a{
			font-family: $content-family;
			font-size: 13px;
			letter-spacing: 1px;
			color: #fff;
			display: inline-block;
			width: 96px;
			height: 38px;
			@include breakpoint(xlarge down){
				font-size: 12px;
				width: 90px;
				height: 32px;
			};
		}
	}
	.phone-area{
		@include breakpoint(medium down){
			border-bottom: 1px solid rgba(#fff, .5);
			padding-bottom: 30px;
		};
		.phone{
			font-family: $content-family;
			font-size: 36px;
			letter-spacing: 3px;
			color: #fff;
			margin-right: 44px;
			@include breakpoint(xlarge down){
				font-size: 25px;
				margin-right: 30px;
			};
			@include breakpoint(medium down){
				margin-right: 0;
				margin-bottom: 20px;
			};
			i{
				font-weight: 700;
				font-size: 12px;
				letter-spacing: 0;
				margin-right: 15px;
				display: inline-block;
				vertical-align: top;
				position: relative;
				top: 5px;
				@include breakpoint(xlarge down){
					top: 2px;
				};
			}
		}
		.email{
			font-family: $content-family;
			font-size: 14px;
			letter-spacing: 1px;
			line-height: 1.3;
			color: #fff;
			@include breakpoint(xlarge down){
				font-size: 12px;
			};
			i{
				font-weight: 700;
				font-size: 12px;
				display: block;
				margin-bottom: 3px;
			}
		}
	}
	.copyright{
		font-family: $content-family;
		font-size: 12px;
		letter-spacing: 1px;
		line-height: 1.5;
		color: #fff;
		@include breakpoint(medium down){
			margin-bottom: 20px;
		};
	}
	.shareList{
		@include breakpoint(medium down){
			padding-left: 12px;
		};
		li{
			@include mr(85px);
			@include breakpoint(xlarge down){
				@include mr(45px);
			};
			@include breakpoint(medium down){
				@include mr(18px);
			};
		}
		.title{
			font-family: $content-family;
			font-weight: 700;
			font-size: 12px;
			letter-spacing: 1px;
			color: #fff;
			margin-right: 8px;
			@include breakpoint(medium down){
				display: none;
			};
		}
		.link{
			transition: all .3s;
			@include mr(8px);
			@include breakpoint(medium down){
				@include mr(18px);
			};
			&:hover{
				opacity: .6;
			}
		}
	}
	.bot-area{
		border-bottom: 1px solid rgba(#fff, .5);
		padding-bottom: 9px;
		margin-top: 35px;
		@include breakpoint(medium down){
			margin-top: 30px;
			padding-bottom: 22px;
		};
	}
}
.trafficWrap{
	max-width: 548px;
	margin: 0 auto -180px;
	position: relative;
	@include breakpoint(xlarge down){
		max-width: 406px;
	}
	@include breakpoint(medium down){
		padding: 0 var(--mobileSpace);
		margin-bottom: -60px;
	};
	.deco-1{
		position: absolute;
		top: -133px;
		left: -270px;
		@include breakpoint(xlarge down){
			top: -53px;
		    left: -201px;
			width: 160px;
		}
		@include breakpoint(medium down){
			top: 92px;
			left: 14px;
			width: 143px;
		};
	}
	.deco-2{
		position: absolute;
		top: 430px;
		right: -370px;
		@include breakpoint(xlarge down){
			top: 342px;
		    right: -280px;
		    width: 216px;
		}
		@include breakpoint(medium down){
			top: 484px;
		    right: auto;
		    left: 6px;
		    width: 205px;
		    z-index: -1;
		};
	}
	.deco-3{
		position: absolute;
		bottom: 511px;
		left: -523px;
		@include breakpoint(xlarge down){
			bottom: 498px;
		    left: -329px;
		    width: 297px;
		}
		@include breakpoint(medium down){
			bottom: 937px;
			left: -44px;
			width: 288px;
			z-index: -1;
		};
	}
	.deco-4{
		position: absolute;
		bottom: 70px;
		right: -379px;
		@include breakpoint(xlarge down){
			bottom: 150px;
			right: -277px;
			width: 224px;
		}
		@include breakpoint(medium down){
			bottom: 251px;
			right: auto;
			left: 12px;
			width: 222px;
			z-index: -1;
		};
	}
	.cloud-1{
		position: absolute;
		top: 278px;
		left: -29px;
		@include breakpoint(xlarge down){
			top: 226px;
		    left: -17px;
		    width: 91px;
		}
		@include breakpoint(medium down){
			top: 125px;
			left: 194px;
		};
	}
	.cloud-2{
		position: absolute;
		top: 687px;
		right: -385px;
		z-index: 1;
		@include breakpoint(xlarge down){
			top: 527px;
		    right: -295px;
		    width: 106px;
		}
		@include breakpoint(medium down){
			top: 547px;
			right: 10px;
			width: 91px;
		};
	}
	.cloud-3{
		position: absolute;
		top: 700px;
		right: -463px;
		@include breakpoint(xlarge down){
			top: 538px;
			right: -343px;
			width: 80px;
		}
		@include breakpoint(medium down){
			top: 556px;
			right: -35px;
			width: 67px;
		};
	}
	.cloud-4{
		position: absolute;
		top: 642px;
		right: -508px;
		@include breakpoint(xlarge down){
			top: 497px;
			right: -368px;
			width: 49px;
		}
		@include breakpoint(medium down){
			top: 668px;
			right: auto;
			width: 46px;
			left: 30px;
		};
	}
	.cloud-5{
		position: absolute;
		bottom: 559px;
		left: -253px;
		@include breakpoint(xlarge down){
			bottom: 529px;
			left: -143px;
			width: 91px;
		}
		@include breakpoint(medium down){
			bottom: 1006px;
			left: auto;
			width: 79px;
			right: -20px;
		};
	}
	.cloud-6{
		position: absolute;
		bottom: 82px;
	    right: -412px;
	    @include breakpoint(xlarge down){
	    	bottom: 157px;
    	    right: -312px;
    	    width: 91px;
	    }
	    @include breakpoint(medium down){
	    	bottom: 271px;
	    	right: 12px;
	    	width: 82px;
	    };
	}
	.cloud-7{
		position: absolute;
		bottom: 78px;
	    right: -449px;
	    @include breakpoint(xlarge down){
	    	bottom: 156px;
    	    right: -335px;
    	    width: 49px;
	    }
	    @include breakpoint(medium down){
	    	bottom: 267px;
	    	right: -20px;
	    };
	}
	.cloud-8{
		position: absolute;
		bottom: 110px;
		right: -553px;
		@include breakpoint(xlarge down){
			bottom: 185px;
			right: -393px;
			width: 80px;
		}
		@include breakpoint(medium down){
			bottom: 303px;
			right: -60px;
		};
	}
}
.traffic-head{
	padding-left: 100px;
	margin: 140px 0 320px;
	position: relative;
	@include breakpoint(xlarge down){
		padding-left: 53px;
	    margin: 60px 0 230px;
	}
	@include breakpoint(medium down){
		padding-left: 0;
		margin: 25px 0 330px;
	};
	.deco{
		position: absolute;
		top: 0;
		left: 0;
		@include breakpoint(medium down){
			display: none;
		};
	}
	.head{
		@extend .m-head;
	}
	// .btn{
	// 	background-color: $blue;
	// 	border-radius: 50px;
	// 	display: inline-block;
	// 	padding: 13px 42px;
	// 	margin-top: 62px;
	// 	@include breakpoint(medium down){
	// 		margin-top: 40px;
	// 		text-align: center;
	// 		padding: 12px 35px;
	// 	};
	// 	.ch{
	// 		font-family: $ch-family;
	// 		font-size: 20px;
	// 		letter-spacing: 3px;
	// 		color: #fff;
	// 		margin-bottom: 6px;
	// 		@include breakpoint(medium down){
	// 			font-size: 15px;
	// 			letter-spacing: 2px;
	// 		};
	// 	}
	// 	.en{
	// 		font-family: $content-family;
	// 		font-weight: 700;
	// 		font-size: 12px;
	// 		letter-spacing: 1px;
	// 		color: #fff;
	// 	}
	// }
}
.traffic-type{
	margin-bottom: 180px;
    height: 368px;
    @include breakpoint(xlarge down){
    	margin-bottom: 88px;
    }
	@include breakpoint(medium down){
		margin-bottom: 60px;
		height: 250px;
		@include flex-align(center);
	};
	.time{
		font-family: $content-family;
		font-weight: 700;
		font-size: 17px;
		letter-spacing: 2px;
		color: #fff;
		background-color: $blue;
		padding: 15px 7px;
		writing-mode: tb;
	    // text-orientation: upright;
	    @include breakpoint(xlarge down){
	    	font-size: 13px;
	    };
	}
	.pathList{
		margin-left: 33px;
		position: relative;
		margin-top: 18px;
		@include breakpoint(xlarge down){
			margin-left: 18px;
		}
		@include breakpoint(medium down){
			min-width: 220px;
			margin-left: 25px;
		};
		&:after{
			content: '';
			position: absolute;
			top: -15px;
			left: 15px;
			width: calc(100% - 30px);
			height: 1px;
			background: linear-gradient(to right, $blue 10px, transparent 10px) 0 0/19px 19px repeat;
		}
		li{
			font-family: $content-family;
			font-weight: 700;
			font-size: 18px;
			letter-spacing: 2px;
			color: $blue;
			writing-mode: tb;
		    text-orientation: upright;
		    padding: 0 10px;
		    border-right: 1px solid $blue;
		    border-left: 1px solid $blue;
		    position: relative;
		    @include mr(40px);
		    @include breakpoint(xlarge down){
		    	font-size: 13px;
		    	padding: 0 9px;
		    	@include mr(30px);
		    };
		    @include breakpoint(medium down){
		    	font-size: 12px;
		    	padding: 0 6px;
		    	margin-right: 23px;
		    }
		    &:first-child{
		    	border-left: none;
		    }
		    &:last-child{
		    	border-right: none;
		    }
		    &:before{
		    	content: '';
		    	width: 8px;
		    	height: 8px;
		    	background-color: $blue;
		    	border-radius: 50%;
		    	position: absolute;
		    	top: -18px;
		    	left: 50%;
		    	margin-left: -4px;
		    }
		}
	}
	.title-area{
		margin-left: 60px;
		margin-top: -56px;
		@include breakpoint(xlarge down){
			margin-left: 33px;
			margin-top: -32px;
		}
		.icon{
			margin-bottom: 15px;
			text-align: center;
			@include breakpoint(xlarge down){
				max-width: 42px;
			}
		}
		.title{
			font-family: $ch-family;
			font-weight: 600;
			font-size: 24px;
			letter-spacing: 4px;
			line-height: 1.3;
			color: $blue;
			writing-mode: tb;
		    text-orientation: upright;
		    @include breakpoint(xlarge down){
		    	font-size: 16px;
		    }
		}
	}
	.btn{
		text-align: center;
		display: block;
		width: 100%;
		margin-top: 50px;
		@include breakpoint(xlarge down){
			margin-top: 32px;
		}
		a{
			background-color: $blue;
			border-radius: 50px;
			display: inline-block;
			padding: 14px 46px;
			@include breakpoint(xlarge down){
				padding: 10px 36px;
			}
			.ch{
				font-family: $ch-family;
				font-size: 20px;
				letter-spacing: 3px;
				color: #fff;
				margin-bottom: 7px;
				@include breakpoint(xlarge down){
					font-size: 15px;
				}
			}
			.en{
				font-family: $content-family;
				font-weight: 700;
				font-size: 12px;
				letter-spacing: 1px;
				color: #fff;
			}
		}
	}
}
.mobile-traffic-title{
	margin-bottom: 40px;
	.icon{
		margin-bottom: 15px;
	}
	.title{
		font-family: $ch-family;
		font-weight: 600;
		font-size: 15px;
		letter-spacing: 3px;
		line-height: 1.5;
		color: $blue;
		writing-mode: tb;
	    text-orientation: upright;
	}
}
.mobile-traffic-btn{
	text-align: center;
	display: block;
	width: 100%;
	margin-bottom: 46px;
	a{
		background-color: $blue;
		border-radius: 50px;
		display: inline-block;
		padding: 10px 36px;
		.ch{
			font-family: $ch-family;
			font-size: 15px;
			letter-spacing: 3px;
			color: #fff;
			margin-bottom: 7px;
		}
		.en{
			font-family: $content-family;
			font-weight: 700;
			font-size: 12px;
			letter-spacing: 1px;
			color: #fff;
		}
	}
}
.traffic-banner-deco-car{
	position: absolute;
	right: 160px;
    bottom: -266px;
    @include breakpoint(xlarge down){
    	right: 98px;
    	bottom: -155px;
    	width: 378px;
    }
    @include breakpoint(medium down){
    	right: -35px;
	    bottom: -101px;
	    width: 233px;
    };
}
.traffic-banner-deco-cloud-1{
	position: absolute;
	right: 85px;
	bottom: -223px;
	@include breakpoint(xlarge down){
		right: 65px;
		bottom: -153px;
		width: 91px;
	}
	@include breakpoint(medium down){
		right: 18px;
		bottom: -93px;
		width: 75px;
	};
}
.traffic-banner-deco-cloud-2{
	position: absolute;
	right: 51px;
	bottom: -229px;
	@include breakpoint(xlarge down){
		right: 36px;
	    bottom: -155px;
	    width: 49px;
	}
	@include breakpoint(medium down){
		right: 1px;
		bottom: -99px;
		width: 40px;
	};
}
.traffic-banner-deco-cloud-3{
	position: absolute;
	right: -18px;
	bottom: -186px;
	@include breakpoint(xlarge down){
		right: -7px;
		bottom: -120px;
		width: 80px;
	}
	@include breakpoint(medium down){
		right: -47px;
		bottom: -71px;
		width: 66px;
	};
}
.tripWrap{
	max-width: 1150px;
	margin: 162px auto 0;
	position: relative;
	@include breakpoint(xlarge down){
		max-width: 780px;
		margin: 133px auto 0;
	}
	@include breakpoint(medium down){
		margin: 100px auto 0;
		padding: 0 var(--mobileSpace);
	};
}
.trip-head{
	position: relative;
	margin-bottom: 130px;
	@include breakpoint(xlarge down){
		margin-bottom: 100px;
	}
	@include breakpoint(medium down){
		margin-bottom: -14px;
	};
	.scroll{
		position: absolute;
		top: 0;
		left: -100px;
		@include breakpoint(xlarge down){
			left: -53px;
		}
	}
	.head{
		@extend .m-head;
	}
}
.trip-article{
	max-width: 522px;
	position: relative;
	@include breakpoint(xlarge down){
		max-width: 360px;
	}
	.pic-area{
		position: relative;
		margin-bottom: 20px;
		.trip-slider{
			width: 520px;
			@include breakpoint(xlarge down){
				width: 360px;
			}
			@include breakpoint(medium down){
				width: 100%;
			}
			/* fade css */
			.flickity-slider {
				transform: none !important;
			}
			li{
				width: 100%;
				/* fade css */
				left: 0 !important;
				opacity: 0;
				transition: opacity 0.5s ease-in-out;
				z-index: -1;
				&.is-selected {
					opacity: 1;
					z-index: 0
				}
			}
			/* position dots in carousel */
			.flickity-page-dots {
				bottom: 8px;
			}
			/* white circles */
			.flickity-page-dots .dot {
				display: inline-block;
				vertical-align: middle;
				width: 6px;
				height: 6px;
				background-color: #fff;
				border-radius: 50%;
				opacity: .4;
				transition: all .5s;
				@include mr(6px);
			}
			/* fill-in selected dot */
			.flickity-page-dots .dot.is-selected {
				opacity: .8;
			}
		}
		.title{
			background-color: #fff;
			position: absolute;
			top: -72px;
			right: 0;
			padding: 18px 14px 18px 16px;
			@include breakpoint(xlarge down){
				top: -42px;
				padding: 10px;
			};
			@include breakpoint(medium down){
				min-width: 63px;
				min-height: 116px;
			}
			span{
				font-family: $ch-family;
				font-weight: 600;
				font-size: 20px;
				letter-spacing: 3px;
				line-height: 1.3;
				color: $blue;
				display: block;
				writing-mode: vertical-lr;
				border-left: 1px solid $blue;
				padding-left: 8px;
				@include breakpoint(xlarge down){
					font-size: 14px;
					letter-spacing: 2px;
					padding-left: 6px;
				};
			}
		}
	}
	.content-area{
		height: 210px;
		@include breakpoint(xlarge down){
			height: 190px;
		};
		.btn{
			background-color: $blue;
			border-radius: 50px;
			padding: 42px 14px;
			cursor: pointer;
			@include breakpoint(xlarge down){
				padding: 25px 10px;
			};
			.en{
				font-family: $content-family;
				font-weight: 700;
				font-size: 12px;
				letter-spacing: 1px;
				color: #fff;
				margin-right: 6px;
				padding-top: 3px;
				writing-mode: tb;
			}
			.ch{
				font-family: $ch-family;
				font-size: 20px;
				letter-spacing: 3px;
				color: #fff;
				writing-mode: tb;
				@include breakpoint(xlarge down){
					font-size: 14px;
					letter-spacing: 2px;
				};
			}
		}
		.content{
			font-family: $content-family;
			font-weight: 700;
			font-size: 15px;
			letter-spacing: 1px;
			line-height: 43px;
			color: $blue;
			writing-mode: tb;
			// text-orientation: upright;
			background: linear-gradient(to left, transparent 42px, $blue 42px) top right/43px 43px repeat;
			@include breakpoint(xlarge down){
				font-size: 12px;
				line-height: 32px;
				background: linear-gradient(to left, transparent 31px, $blue 31px) top right/32px 32px repeat;
			};
			@include breakpoint(medium down){
				font-size: 12px;
				line-height: 27px;
				background: linear-gradient(to left, transparent 26px, $blue 26px) top right/27px 27px repeat;
			};
		}
	}
}
.trip-topList{
	margin-bottom: 240px;
    position: relative;
    @include breakpoint(xlarge down){
    	margin-bottom: 200px;
    }
    @include breakpoint(medium down){
    	margin-bottom: 110px;
    }
	section{
		@include mb(212px);
		@include breakpoint(xlarge down){
			@include mb(142px);
		}
		@include breakpoint(medium down){
			@include mb(60px);
		}
		&:nth-child(even){
			margin-left: auto;
		}
	}
	#tt-1{
		.deco{
			position: absolute;
			top: -180px;
			left: calc(100% + 226px);
			@include breakpoint(xlarge down){
				top: -168px;
			    left: calc(100% + 60px);
			}
			@include breakpoint(medium down){
				top: 0;
				left: -34px;
				position: relative;
				margin-bottom: 70px;
			}
			img{
				max-width: initial;
				@include breakpoint(xlarge down){
					max-width: 592px;
				}
				@include breakpoint(medium down){
					max-width: 412px;
				}
			}
		}
	}
	#tt-2{
		.deco{
			position: absolute;
			top: -160px;
			right: calc(100% + 171px);
			@include breakpoint(xlarge down){
				top: -110px;
			    right: calc(100% + 101px);
			}
			@include breakpoint(medium down){
				top: 0;
			    right: auto;
			    position: relative;
			    left: -120px;
			    margin-bottom: 20px;
			}
			img{
				max-width: initial;
				@include breakpoint(xlarge down){
					max-width: 597px;
				}
				@include breakpoint(medium down){
					max-width: 472px;
				}
			}
		}
	}
	#tt-3{
		.deco{
			position: absolute;
			top: -108px;
			left: calc(100% + 67px);
			@include breakpoint(xlarge down){
				top: -68px;
				left: calc(100% + 36px);
			}
			@include breakpoint(medium down){
				top: 0;
				left: -61px;
				position: relative;
				margin-bottom: 47px;
			}
			img{
				max-width: initial;
				@include breakpoint(xlarge down){
				    max-width: 696px;
				}
				@include breakpoint(medium down){
					max-width: 658px;
				}
			}
		}
	}
	#tt-4{
		.deco{
			position: absolute;
			top: -80px;
			right: calc(100% + 271px);
			@include breakpoint(xlarge down){
				top: -30px;
				right: calc(100% + 153px);
			}
			@include breakpoint(medium down){
				top: 0;
				right: auto;
				position: relative;
				left: 23px;
				margin-bottom: 30px;
			}
			img{
				max-width: initial;
				@include breakpoint(xlarge down){
					max-width: 297px;
				}
				@include breakpoint(medium down){
					max-width: 247px;
				}
			}
		}
	}
	.cloud-1{
		position: absolute;
		top: 3.2%;
	    right: -344.1px;
		z-index: 2;
		@include breakpoint(xlarge down){
			top: 2.8%;
		    right: -214px;
		    width: 133px;
		}
		@include breakpoint(medium down){
			top: 6%;
		    right: 10px;
		    width: 95px;
		};
	}
	.cloud-2{
		position: absolute;
		top: 4%;
		right: -431px;
		z-index: 1;
		@include breakpoint(xlarge down){
			top: 3.4%;
		    right: -274px;
		    width: 101px;
		}
		@include breakpoint(medium down){
			top: 6.3%;
			right: -40px;
			width: 74px;
		};
	}
	.cloud-3{
		position: absolute;
		top: 19%;
	    right: 31.2%;
	    @include breakpoint(xlarge down){
	    	top: 20%;
	    	right: 35.2%;
	    	width: 113px;
	    }
	    @include breakpoint(medium down){
	    	top: 13.3%;
    	    right: auto;
    	    left: -15px;
    	    width: 94px;
    	    z-index: 1;
	    };
	}
	.cloud-4{
		position: absolute;
		top: 44%;
	    left: -260px;
	    @include breakpoint(xlarge down){
	    	left: -180px;
	    	width: 91px;
	    }
		@include breakpoint(medium down){
			display: none;
		};
	}
	.cloud-5{
		position: absolute;
		top: 45.8%;
	    left: -123px;
	    @include breakpoint(xlarge down){
    	    left: -97px;
    	    width: 49px;
	    }
		@include breakpoint(medium down){
			display: none;
		};
	}
	.cloud-6{
		position: absolute;
		top: 49.7%;
		right: -200px;
		@include breakpoint(xlarge down){
			top: 50%;
			right: -156px;
			width: 40px;
		}
	    @include breakpoint(medium down){
			top: auto;
			bottom: 24.5%;
			right: 10px;
		};
	}
	.cloud-7{
		position: absolute;
		top: 48.3%;
		right: -355px;
		@include breakpoint(xlarge down){
			top: 48.9%;
			right: -247px;
			width: 78px;
		}
		@include breakpoint(medium down){
			top: auto;
			bottom: 25%;
			right: -70px;
		};
	}
	.cloud-8{
		position: absolute;
		top: 63.9%;
		left: -133px;
	    z-index: 1;
	    @include breakpoint(xlarge down){
	    	top: 62.7%;
	    	left: -53px;
	    	width: 106px;
	    }
	    @include breakpoint(medium down){
	    	top: auto;
	    	left: -5px;
	    	bottom: 12%;
	    };
	}
}
.tripList{
	margin-bottom: 120px;
	position: relative;
	@include breakpoint(xlarge down){
		margin-bottom: 90px;
	}
	@include breakpoint(medium down){
		margin-bottom: 170px;
	}
	section{
		margin-bottom: 190px;
		@include breakpoint(xlarge down){
			margin-bottom: 120px;
		}
		@include breakpoint(medium down){
			margin-bottom: 110px;
		}
	}
	.cloud-1{
		position: absolute;
		top: 5.2%;
	    right: -374.1px;
	    @include breakpoint(xlarge down){
	    	top: 4.2%;
    	    right: -228.1px;
    	    width: 91px;
	    }
	    @include breakpoint(medium down){
	    	top: 30.4%;
	    	right: 0;
	    	width: 91px;
	    	z-index: 1;
	    }
	}
	.cloud-2{
		position: absolute;
		top: 6.4%;
		right: -421px;
		@include breakpoint(xlarge down){
			top: 5.1%;
			right: -261px;
			width: 49px;
		}
		@include breakpoint(medium down){
			top: 30.8%;
		    right: -32px;
		    width: 49px;
		    z-index: 1;
		}
	}
	.cloud-3{
		position: absolute;
		top: 26.6%;
		left: 0;
		@include breakpoint(xlarge down){
			top: 27.8%;
		    width: 124px;
		}
		@include breakpoint(medium down){
			display: none;
		}
	}
	.cloud-4{
		position: absolute;
		top: 54%;
	    left: -19%;
	    @include breakpoint(xlarge down){
	    	top: 52%;
	    	left: -18%;
	    	width: 78px;
	    }
	    @include breakpoint(medium down){
	    	top: 63.8%;
	    	left: 3%;
	    	width: 74px;
	    }
	}
	.cloud-5{
		position: absolute;
		top: 55.8%;
		left: -25%;
		@include breakpoint(xlarge down){
			top: 54.2%;
			left: -23%;
			width: 40px;
		}
		@include breakpoint(medium down){
			top: 64.2%;
			left: 20%;
			width: 40px;
			z-index: 1;
		}
	}
	.cloud-6{
		position: absolute;
		top: 64%;
		right: -36%;
		@include breakpoint(xlarge down){
			right: -33%;
		    width: 113px;
		}
		@include breakpoint(medium down){
			display: none;
		}
	}
	.deco-people{
		position: absolute;
		right: -347px;
		top: 15.2%;
		@include breakpoint(xlarge down){
			right: -219px;
			top: 17.2%;
		}
		@include breakpoint(medium down){
			top: auto;
		    bottom: -70px;
		    right: 24px;
		};
		img{
			max-width: initial;
			@include breakpoint(xlarge down){
				max-width: 168px;
			};
		}
	}
}
.trip-findmore{
	text-align: center;
	margin-bottom: 150px;
	position: relative;
	@include breakpoint(xlarge down){
		margin-bottom: 110px;
	}
	.slogan{
		font-family: $ch-family;
		font-weight: 700;
		font-size: 27px;
		letter-spacing: 4px;
		line-height: 1.4;
		color: $blue;
		@include breakpoint(xlarge down){
			font-size: 20px;
		}
		@include breakpoint(medium down){
			font-size: 14px;
			letter-spacing: 3px;
		};
	}
	.btn{
		background-color: $blue;
		border-radius: 50px;
		display: inline-block;
		padding: 14px 60px;
		margin-top: 70px;
		@include breakpoint(xlarge down){
			padding: 10px 40px;
			margin-top: 40px;
		}
		.ch{
			font-family: $ch-family;
			font-size: 20px;
			letter-spacing: 3px;
			color: #fff;
			margin-bottom: 7px;
			@include breakpoint(xlarge down){
				font-size: 14px;
			};
		}
		.en{
			font-family: $content-family;
			font-weight: 700;
			font-size: 12px;
			letter-spacing: 1px;
			color: #fff;
		}
	}
	.cloud-1{
		position: absolute;
		top: 110px;
	    left: 8%;
	    @include breakpoint(xlarge down){
	    	width: 106px;
	    }
	    @include breakpoint(medium down){
	    	top: 169px;
	    	left: 10%;
	    	width: 96px;
	    };
	}
	.cloud-2{
		position: absolute;
		top: 14px;
	    left: 25%;
	    @include breakpoint(xlarge down){
	    	width: 91px;
	    }
	    @include breakpoint(medium down){
	    	top: 25px;
    	    left: -19px;
    	    width: 80px;
	    };
	}
	.cloud-3{
		position: absolute;
		top: 56px;
		left: 31.8%;
		@include breakpoint(xlarge down){
			top: 49px;
			left: 30.5%;
			width: 49px;
		}
		@include breakpoint(medium down){
			top: 50px;
		    left: 13.8%;
		    width: 44px;
		};
	}
	.cloud-4{
		position: absolute;
		top: 133px;
		right: 33%;
		@include breakpoint(xlarge down){
			top: 83px;
		    right: 31%;
		    width: 106px;
		}
		@include breakpoint(medium down){
			top: 75px;
			right: -44px;
			width: 110px;
		};
	}
	.cloud-5{
		position: absolute;
		top: 155px;
		right: 32.8%;
		@include breakpoint(xlarge down){
			top: 98px;
			right: 31%;
			width: 49px;
		}
		@include breakpoint(medium down){
			top: 91px;
			right: -47px;
			width: 50px;
		};
	}
	.cloud-6{
		position: absolute;
		top: 145px;
		right: 27%;
		@include breakpoint(xlarge down){
			top: 88px;
			right: 24%;
			width: 55px;
		}
		@include breakpoint(medium down){
			display: none;
		};
	}
	.cloud-7{
		position: absolute;
		top: 63px;
	    right: 16.7%;
	    @include breakpoint(xlarge down){
	    	top: 43px;
    	    right: 12.7%;
    	    width: 73px;
	    }
	    @include breakpoint(medium down){
	    	display: none;
	    };
	}
}
.m-head{
	.ch{
		font-family: $ch-family;
		font-weight: 600;
		font-size: 50px;
		letter-spacing: 9px;
		color: $blue;
		margin-bottom: 15px;
		@include breakpoint(xlarge down){
			font-size: 38px;
			letter-spacing: 6px;
		};
		@include breakpoint(medium down){
			font-size: 27px;
			letter-spacing: 4px;
			margin-bottom: 10px;
		}
	}
	.en{
		font-family: $en-family;
		font-weight: 700;
		font-size: 26px;
		letter-spacing: 3px;
		color: $blue;
		@include breakpoint(xlarge down){
			font-size: 22px;
			letter-spacing: 2px;
		};
		@include breakpoint(medium down){
			font-size: 15px;
			letter-spacing: 1px;
		}
	}
}
.aboutWrap{
	margin-top: 170px;
	position: relative;
	@include breakpoint(medium down){
		margin-top: 90px;
		padding: 0 var(--mobileSpace);
	};
}
.about-head{
	position: relative;
	margin-left: 18.33%;
	margin-bottom: 140px;
	padding-left: 100px;
	@include breakpoint(xlarge down){
		margin-left: 15.33%;
		margin-bottom: 103px;
		padding-left: 53px;
	};
	@include breakpoint(medium down){
		margin-left: 0;
		margin-bottom: 45px;
		padding-left: 0;
	};
	.scroll{
		position: absolute;
		top: 0;
		left: 0;
		@include breakpoint(medium down){
			display: none;
		};
	}
	.head{
		@extend .m-head;
	}
}
.m-about-slogan{
	font-family: $ch-family;
	font-weight: 700;
	font-size: 24px;
	letter-spacing: 2px;
	line-height: 1.6;
	color: $blue;
	@include breakpoint(xlarge down){
		font-size: 18px;
	};
	@include breakpoint(medium down){
		font-size: 15px;
	}
}
.m-about-content{
	font-family: $ch-family;
	font-weight: 600;
	font-size: 17px;
	letter-spacing: 2px;
	line-height: 1.8;
	color: $blue;
	@include breakpoint(xlarge down){
		font-size: 13px;
	};
	@include breakpoint(medium down){
		font-size: 12px;
	}
}
.m-about-inline{
	font-family: $ch-family;
	font-weight: 600;
	font-size: 17px;
	letter-spacing: 2px;
	line-height: 2.1;
	color: $blue;
	background-color: #fff;
	padding: 2px 0;
	display: inline;
    box-shadow: -7px 0 0 #fff;
    @include breakpoint(xlarge down){
    	font-size: 13px;
    };
    @include breakpoint(medium down){
    	font-size: 12px;
    }
}
.about-area-1{
	margin-bottom: 95px;
	position: relative;
	@include breakpoint(xlarge down){
		margin-bottom: 132px;
	};
	@include breakpoint(medium down){
		margin-bottom: 92px;
	}
	.slogan{
		@extend .m-about-slogan;
	    margin: 0 0 65px 23.8%;
	    @include breakpoint(xlarge down){
	    	margin: 0 0 25px 19.5%;
	    };
	    @include breakpoint(medium down){
	    	margin: 0 0 24px 0;
	    }
	}
	.content-1{
		@extend .m-about-content;
	    margin: 0 0 27px 23.8%;
	    @include breakpoint(xlarge down){
	    	margin: 0 0 77px 19.5%;
	    };
	    @include breakpoint(medium down){
	    	margin: 0 0 32px;
	    }
	}
	.content-2{
		@extend .m-about-content;
		margin: 0 0 80px 53%;
		@include breakpoint(xlarge down){
			margin: 0 0 47px 42.2%;
		};
	}
	.wrap-1{
		position: relative;
		.article-area{
			max-width: 384px;
			margin: 0 70px 0 16%;
			@include breakpoint(xlarge down){
				max-width: 266px;
				margin: 86px 70px 0 16%;
			};
			@include breakpoint(medium down){
				max-width: 310px;
			    margin: 34px 0 0 0;
			}
			.title{
				@extend .m-about-slogan;
				margin-bottom: 20px;
			}
			.content{
				@extend .m-about-content;
			}
		}
		.pic-area{
			@include breakpoint(xlarge down){
				max-width: 640px;
			};
			@include breakpoint(medium down){
				max-width: 300px;
				margin-left: calc(var(--mobileSpace) * -1);
			}
		}
	}
	.pic-1{
	    margin: -60px 0 0 16%;
	    @include breakpoint(xlarge down){
	    	margin: 6px 0 0 16%;
	    	img{width: 270px;}
	    };
	    @include breakpoint(medium down){
	    	margin: 25px 0 0 39%
	    }
	}
	.cloud-1{
		position: absolute;
		bottom: 41%;
	    right: 7%;
	    z-index: 1;
	    @include breakpoint(xlarge down){
	    	bottom: 42%;
    	    right: 1.1%;
    	    width: 95px;
	    };
	    @include breakpoint(medium down){
	    	bottom: 49.2%;
	    	right: 1.1%;
	    	width: 81px;
	    }
	}
	.cloud-2{
		position: absolute;
		bottom: 41%;
	    right: 4.9%;
	    z-index: 2;
	    @include breakpoint(xlarge down){
	    	bottom: 42%;
	    	right: -0.5%;
	    	width: 51px;
	    };
	    @include breakpoint(medium down){
	    	bottom: 49%;
	    	right: -7.5%;
	    	width: 43px;
	    }
	}
	.cloud-3{
		position: absolute;
		bottom: 14.8%;
		right: 53.4%;
		z-index: 1;
		@include breakpoint(xlarge down){
			bottom: 19.8%;
		    right: 50.4%;
		    width: 95px;
		};
		@include breakpoint(medium down){
			bottom: 2.4%;
			right: auto;
			width: 90px;
			left: 20.9%;
		}
	}
	.cloud-4{
		position: absolute;
		bottom: 10%;
	    right: 49%;
	    z-index: 2;
	    @include breakpoint(xlarge down){
	    	bottom: 15.8%;
    	    right: 46%;
    	    width: 91px;
	    };
	    @include breakpoint(medium down){
	    	bottom: -3%;
	    	right: auto;
	    	width: 86px;
	    	left: 40%;
	    }
	}
	.cloud-5{
		position: absolute;
		bottom: 10.6%;
		right: 45.9%;
		@include breakpoint(xlarge down){
			bottom: 15.8%;
			right: 43.4%;
			width: 69px;
		};
		@include breakpoint(medium down){
			bottom: -2.8%;
		    right: auto;
		    width: 65px;
		    left: 59%;
		}
	}
}
.about-area-2{
	margin-bottom: 218px;
	position: relative;
	@include breakpoint(medium down){
		margin-bottom: 104px;
	}
	.wrap-1{
		margin-bottom: 58px;
		.article-area{
			margin: 0 -224px -3px 36.7%;
			position: relative;
			@include breakpoint(xlarge down){
				margin: 0px -217px -3px 39.7%;
			};
			@include breakpoint(medium down){
				margin: 0;
			}
			.title{
				@extend .m-about-slogan;
				margin-bottom: 38px;
				@include breakpoint(xlarge down){
					margin-bottom: 17px;
				};
			}
			.content{
				@extend .m-about-inline;
			}
		}
		.pic-area{
			@include breakpoint(xlarge down){
				max-width: 370px;
			};
			@include breakpoint(medium down){
				max-width: 305px;
			    margin-left: auto;
			    margin-right: calc(var(--mobileSpace) * -1);
			}
		}
		.mobile-content{
			margin: -12px 0 0;
			p{
				@extend .m-about-inline;
			}
		}
	}
	.wrap-2{
		.pic-area{
			margin: 0 35px 0 36.5%;
			@include breakpoint(xlarge down){
				margin: 0 27px 0 39.5%;
				max-width: 290px;
			};
			@include breakpoint(medium down){
				margin: 0 calc(var(--mobileSpace) * -1) 15px auto;
				max-width: 235px;
			}
		}
		.article-area{
			@extend .m-about-content;
		}
	}
	.mobile-deco{
		margin-left: calc(var(--mobileSpace) * -1);
		margin-bottom: 50px;
	}
	.deco{
		position: absolute;
		left: -170px;
	    bottom: -125px;
	    @include breakpoint(xlarge down){
	    	left: -90px;
    	    bottom: -115px;
    	    width: 571px;
	    };
	    @include breakpoint(medium down){
	    	left: -55px;
    	    bottom: 130px;
    	    width: 350px;
	    }
	}
	.cloud-1{
		position: absolute;
		top: 42%;
	    left: 26%;
	    @include breakpoint(xlarge down){
	    	top: 41%;
	    	left: 28.4%;
	    	width: 96px;
	    };
	    @include breakpoint(medium down){
	    	top: 40.8%;
	    	left: auto;
	    	right: -10%;
	    }
	}
	.cloud-2{
		position: absolute;
		right: 4%;
	    bottom: -5%;
	    @include breakpoint(xlarge down){
	    	right: -3%;
	    	bottom: 3%;
	    	width: 96px;
	    };
	    @include breakpoint(medium down){
	    	right: auto;
    	    bottom: -65px;
    	    left: 8%;
	    }
	}
	.cloud-3{
		position: absolute;
		right: 2.6%;
		bottom: -5.7%;
		@include breakpoint(xlarge down){
			display: none;
		};
	}
}
.about-area-3{
	position: relative;
	.wrap-1{
		position: relative;
		z-index: 1;
		margin-bottom: 64px;
		.article-area{
			margin: 0 -145px 60px 12%;
			position: relative;
			z-index: 1;
			@include breakpoint(xlarge down){
				margin: 0 -253px 40px 10%;
			};
			@include breakpoint(medium down){
				margin: 0 0 -23px 0;
			}
			.content{
				@extend .m-about-inline;
			}
		}
		.pic-area{
			@include breakpoint(xlarge down){
				max-width: 580px;
			};
			@include breakpoint(medium down){
				margin: 0 calc(var(--mobileSpace) * -1);
				width: calc(100% + var(--mobileSpace) * 2);
			}
		}
	}
	.wrap-2{
		margin-bottom: 116px;
		@include breakpoint(medium down){
			margin-bottom: 56px;
		}
		.article-area{
			margin: 0 -125px -23px 50.7%;
			position: relative;
			@include breakpoint(xlarge down){
				margin: 0 -98px -23px 48.7%;
			};
			@include breakpoint(medium down){
				margin: 0;
			}
			.content{
				@extend .m-about-inline;
				font-size: 18px;
				@include breakpoint(xlarge down){
					font-size: 15px;
				};
				@include breakpoint(medium down){
					font-size: 14px;
				}
			}
		}
		.pic-area{
			@include breakpoint(xlarge down){
				max-width: 250px;
			};
			@include breakpoint(medium down){
				margin: 0 0 30px auto;
			}
		}
	}
	.wrap-3{
		.article-area{
			@extend .m-about-content;
			margin: 0 32px 0 12%;
		    max-width: 400px;
		    @include breakpoint(xlarge down){
		    	margin: 0 32px 0 10%;
	    	    max-width: 290px;
		    };
		    @include breakpoint(medium down){
		    	margin: 0 auto;
		    }
		}
		.pic-area{
			@include breakpoint(medium down){
				margin: 0 calc(var(--mobileSpace) * -1) 33px;
				width: calc(100% + var(--mobileSpace) * 2);
			}
		}
	}
	.deco{
		position: absolute;
		top: 32px;
		right: -453px;
		@include breakpoint(xlarge down){
			top: -43px;
			right: -180px;
			width: 670px;
		};
		@include breakpoint(medium down){
			top: 0;
			right: 0;
			width: 533px;
			position: relative;
			margin: 0 0 70px -50px;
		}
	}
	.cloud-1{
		position: absolute;
		top: 37.8%;
	    left: 19%;
	    z-index: 3;
	    @include breakpoint(xlarge down){
	    	top: 28.8%;
    	    left: 14%;
    	    width: 111px;
	    };
	    @include breakpoint(medium down){
	    	top: 36.2%;
    	    left: -3%;
	    }
	}
	.cloud-2{
		position: absolute;
		top: 39%;
		left: 25%;
		z-index: 2;
		@include breakpoint(xlarge down){
			top: 29.9%;
			left: 20.1%;
			width: 84px;
		};
		@include breakpoint(medium down){
			top: 37%;
		}
	}
	.cloud-3{
		position: absolute;
		top: 61%;
		right: 9%;
		z-index: 1;
		@include breakpoint(xlarge down){
			top: 36%;
			right: 6%;
			width: 127px;
		};
		@include breakpoint(medium down){
			top: 41.2%;
			right: -22%;
		}
	}
	.cloud-4{
		position: absolute;
		bottom: 13%;
		left: 58%;
		z-index: 1;
		@include breakpoint(xlarge down){
			bottom: 24%;
			left: 70%;
			width: 96px;
		};
		@include breakpoint(medium down){
			bottom: 27.7%;
			left: auto;
			right: -13%;
		}
	}
	.cloud-5{
		position: absolute;
		bottom: -9%;
	    left: 9%;
	    @include breakpoint(xlarge down){
	    	bottom: -12.2%;
	    	left: 6.1%;
	    	width: 96px;
	    };
	    @include breakpoint(medium down){
	    	bottom: -4.2%;
	    }
	}
	.cloud-6{
		position: absolute;
		bottom: -9.7%;
		left: 15%;
		@include breakpoint(xlarge down){
			bottom: -12.7%;
		    left: 12%;
		    width: 52px;
		};
		@include breakpoint(medium down){
			bottom: -4.3%;
		    left: 27.1%;
		}
	}
}
.about-bot-area{
	position: relative;
	text-align: center;
	margin-bottom: 400px;
	@include breakpoint(xlarge down){
		margin-bottom: 210px;
	};
	@include breakpoint(medium down){
		margin-bottom: 169px;
	}
	.line{
		width: 1px;
		height: 383px;
		background-color: $blue;
		margin: 75px auto;
		@include breakpoint(xlarge down){
			height: 260px;
			margin: 33px auto;
		};
		@include breakpoint(medium down){
			height: 153px;
			margin: 50px auto 30px;
		};
	}
	.title{
		font-family: $ch-family;
		font-weight: 600;
		font-size: 33px;
		letter-spacing: 5px;
		line-height: 1.3;
		color: $blue;
		margin-bottom: 16px;
		@include breakpoint(xlarge down){
			font-size: 24px;
			letter-spacing: 2px;
			margin-bottom: 7px;
		};
		@include breakpoint(medium down){
			font-size: 18px;
			letter-spacing: 2px;
			margin-bottom: 14px;
		};
	}
	.content{
		font-family: $ch-family;
		font-weight: 600;
		font-size: 17px;
		letter-spacing: 2px;
		line-height: 1.6;
		color: $blue;
		margin-bottom: 60px;
		@include breakpoint(xlarge down){
			font-size: 13px;
			margin-bottom: 32px;
		};
		@include breakpoint(medium down){
			font-size: 12px;
			letter-spacing: 1px;
			margin-bottom: 38px;
		};
	}
	.pic{
		max-width: 1138px;
		margin-left: 11.41%;
		@include breakpoint(xlarge down){
			max-width: 770px;
			margin: 0 auto;
		};
		@include breakpoint(medium down){
			margin-left: calc(var(--mobileSpace) * -1);
			margin-right: 0;
		};
	}
	.deco{
		position: absolute;
		right: 17%;
	    bottom: -306px;
	    @include breakpoint(xlarge down){
	    	right: 51px;
	    	bottom: -146px;
	    };
	    @include breakpoint(medium down){
	    	right: -27px;
    	    bottom: -143px;
	    };
	    img{
	    	max-width: initial;
	    	@include breakpoint(xlarge down){
	    		max-width: 430px;
	    	};
	    	@include breakpoint(medium down){
	    		max-width: 248px;
	    	};
	    }
	}
}
.vegetarianWrap{
	padding: 0 200px;
	position: relative;
	@include breakpoint(xlarge down){
		padding: 0 140px;
	};
	@include breakpoint(medium down){
		padding: 0 var(--mobileSpace);
		margin-top: 310px;
	};
	.vegetarian-cloud-1{
		position: absolute;
		top: 10.4%;
		right: 9.5%;
		@include breakpoint(xlarge down){
			top: 349px;
			right: 8.7%;
			width: 49px;
		};
		@include breakpoint(medium down){
			top: -20px;
		    right: 11.4%;
		}
	}
	.vegetarian-cloud-2{
		position: absolute;
		top: 11.3%;
		right: 1.5%;
		@include breakpoint(xlarge down){
			top: 375px;
		    right: 0.1%;
		    width: 91px;
		};
		@include breakpoint(medium down){
			top: 38px;
		    right: -11%;
			width: 80px;
		};
	}
	.vegetarian-cloud-3{
		position: absolute;
		top: 12.2%;
		left: 8%;
		z-index: 1;
		@include breakpoint(xlarge down){
			top: 469px;
			left: 9%;
			width: 91px;
		};
		@include breakpoint(medium down){
			top: 8.9%;
		    left: 20.7%;
		    width: 82px;
		};
	}
	.vegetarian-cloud-4{
		position: absolute;
		top: 14.3%;
	    left: -53px;
	    z-index: 1;
	    @include breakpoint(xlarge down){
	    	top: 547px;
    	    left: 67px;
    	    width: 106px;
	    };
	    @include breakpoint(medium down){
	    	top: 10.7%;
	    	left: -10px;
    	    width: 96px;
	    };
	}
	.vegetarian-cloud-5{
		position: absolute;
		top: 14.7%;
		left: 61px;
		@include breakpoint(xlarge down){
			top: 558px;
			left: 141px;
			width: 80px;
		};
		@include breakpoint(medium down){
			top: 11%;
			left: 62px;
		    width: 72px;
		};
	}
}
.vegetarian-head{
	padding-left: 100px;
	position: relative;
	margin: 268px 0 200px;
	@include breakpoint(xlarge down){
		padding-left: 53px;
		position: relative;
		margin: 177px 0 170px;
	};
	@include breakpoint(medium down){
		padding-left: 0;
		margin: 0 0 30px;
	};
	.deco{
		position: absolute;
		top: 0;
		left: 0;
		@include breakpoint(medium down){
			display: none;
		};
	}
	.head{
		@extend .m-head;
	}
}
.vegetarian-top-pic{
	margin-bottom: 270px;
	@include breakpoint(xlarge down){
		margin-bottom: 80px;
		max-width: 660px;
	};
	@include breakpoint(medium down){
	    margin: 30px calc(var(--mobileSpace) * -1) 130px;
	};
}
.vegetatian-slider-wrap{
	position: relative;
	max-width: 1020px;
	@include breakpoint(medium down){
		margin: 0 calc(var(--mobileSpace) * -1);
	};
	.vegetatian-slider{
		li{
			width: 100%;
		}
	}
	/* position dots in carousel */
	.flickity-page-dots {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 12px;
	}
	/* white circles */
	.flickity-page-dots .dot {
		display: inline-block;
		vertical-align: middle;
		width: 6px;
		height: 6px;
		background-color: #fff;
		border-radius: 50%;
		opacity: .5;
		@include mr(8px);
		&.current{
			opacity: 1;
		}
	}
	/* fill-in selected dot */
	.flickity-page-dots .dot.is-selected {
		opacity: 1;
	}
}
.m-vegetarian-title{
	.ch{
		font-family: $ch-family;
		font-weight: 600;
		font-size: 36px;
		letter-spacing: 6px;
		color: $blue;
		margin-bottom: 10px;
		@include breakpoint(xlarge down){
			font-size: 24px;
			letter-spacing: 4px;
			margin-bottom: 7px;
		};
		@include breakpoint(medium down){
			font-size: 20px;
			letter-spacing: 3px;
		};
	}
	.en{
		font-family: $en-family;
		font-weight: 700;
		font-size: 26px;
		letter-spacing: 3px;
		color: $blue;
		@include breakpoint(xlarge down){
			font-size: 18px;
			letter-spacing: 2px;
		};
		@include breakpoint(medium down){
			font-size: 14px;
		};
	}
}
.vegetarian-pic-1{
	position: relative;
	margin-bottom: 143px;
	@include breakpoint(xlarge down){
		margin-bottom: 90px;
	};
	@include breakpoint(medium down){
		@include flex-align(left, bottom);
	}
	.pic{
		margin-right: 24px;
		@include breakpoint(xlarge down){
			max-width: 347px;
		};
		@include breakpoint(medium down){
			margin-right: 15px;
		};
	}
	.article-area{
		max-width: 504px;
		padding-top: 36px;
		position: relative;
		z-index: 1;
		@include breakpoint(medium down){
			padding-top: 0;
			max-width: 113px;
		};
		.title-area{
			@extend .m-vegetarian-title;
			margin-bottom: 30px;
			@include breakpoint(medium down){
				margin-bottom: 0;
			}
		}
		.content{
			font-family: $content-family;
			font-weight: 700;
			font-size: 17px;
			letter-spacing: 1px;
			line-height: 1.6;
			color: $blue;
			@include breakpoint(xlarge down){
				font-size: 13px;
			};
		}
	}
	.mobile-content{
		font-family: $content-family;
		font-weight: 700;
		font-size: 13px;
		letter-spacing: 1px;
		line-height: 1.6;
		color: $blue;
		margin-top: 26px;
	}
	.cloud-1{
		position: absolute;
		top: 246px;
		left: 500px;
		@include breakpoint(xlarge down){
			top: 165px;
		    left: 339px;
		    width: 49px;
		};
		@include breakpoint(medium down){
			top: 129px;
			left: 7px;
		}
	}
	.fog-1{
		position: absolute;
		top: -52px;
		left: 390px;
		@include breakpoint(xlarge down){
			top: -23px;
			left: 260px;
			width: 278px;
		};
		@include breakpoint(medium down){
			top: -52px;
		    left: 47px;
		    width: 222px;
		};
	}
}
.vegetarian-pic-2{
	margin-left: 47.64%;
	margin-bottom: -264px;
	position: relative;
	@include breakpoint(xlarge down){
		margin-bottom: -178px;
	};
	@include breakpoint(medium down){
		margin-left: 0;
	    margin-bottom: 60px;
	};
	.title-area{
		@extend .m-vegetarian-title;
		margin-bottom: 62px;
		position: relative;
		z-index: 1;
		@include breakpoint(xlarge down){
			margin-bottom: 41px;
		};
	}
	.pic-1{
		@include breakpoint(xlarge down){
			width: 158px;
		};
		@include breakpoint(medium down){
			width: 42%;
		};
	}
	.pic-2{
		position: absolute;
		top: 54px;
		left: 207px;
		@include breakpoint(xlarge down){
			top: 42px;
			left: 130px;
			width: 158px;
		};
		@include breakpoint(medium down){
			left: 33%;
			width: 42%;
		};
	}
	.content{
		font-family: $content-family;
		font-weight: 700;
		font-size: 17px;
		letter-spacing: 1px;
		line-height: 1.6;
		color: $blue;
		position: absolute;
		left: 280px;
		bottom: -29px;
	    max-width: 450px;
	    @include breakpoint(xlarge down){
	    	font-size: 13px;
			left: 180px;
			bottom: -25px;
			max-width: 280px;
	    };
	    @include breakpoint(medium down){
	    	position: relative;
    	    left: 0;
    	    bottom: 0px;
    	    width: 54%;
    	    margin-left: 47%;
    	    margin-top: -18px;
	    };
	}
	.cloud-1{
		position: absolute;
		left: -86px;
	    bottom: -67px;
	    z-index: 1;
	    @include breakpoint(xlarge down){
	    	left: -36px;
    	    bottom: -42px;
    	    width: 106px;
	    };
	    @include breakpoint(medium down){
	    	left: -10px;
    	    bottom: 17%;
    	    width: 82px;
	    };
	}
	.cloud-2{
		position: absolute;
		left: 30px;
		bottom: -64px;
		@include breakpoint(xlarge down){
			left: 46px;
			bottom: -40px;
			width: 80px;
		};
		@include breakpoint(medium down){
			left: 47px;
		    bottom: 16.8%;
		    width: 66px;
		};
	}
	.fog-1{
		position: absolute;
		top: -232px;
		left: -79px;
		@include breakpoint(xlarge down){
			top: -138px;
			left: -39px;
			width: 380px;
		};
		@include breakpoint(medium down){
			top: -74px;
			left: 10px;
			width: 330px;
		};
	}
}
.vegetarian-pic-3{
	position: relative;
	margin: 0 0 149px 11%;
	@include breakpoint(xlarge down){
		margin: 0 0 116px 10.6%;
	};
	@include breakpoint(medium down){
		margin: 0 0 33px 0;
	};
	.title-area{
		@extend .m-vegetarian-title;
		margin-bottom: 28px;
		position: relative;
		z-index: 1;
		@include breakpoint(xlarge down){
			margin-bottom: 16px;
		};
	}
	.pic{
		@include breakpoint(xlarge down){
			max-width: 219px;
		};
		@include breakpoint(medium down){
			width: 178px;
			margin: 0 0 30px;
		};
	}
	.content{
		font-family: $content-family;
		font-weight: 700;
		font-size: 17px;
		letter-spacing: 1px;
		line-height: 1.6;
		color: $blue;
		position: absolute;
		left: 161px;
		bottom: 0;
		max-width: 540px;
		background-color: #efefef;
		padding: 14px 20px 37px;
		@include breakpoint(xlarge down){
			font-size: 13px;
			left: 104px;
			max-width: 340px;
			padding: 14px 20px 0;
		};
		@include breakpoint(medium down){
			position: relative;
			left: 0;
			padding: 0;
		};
	}
	.cloud-1{
		position: absolute;
		left: 60px;
	    bottom: -47px;
	    @include breakpoint(xlarge down){
	    	left: -50px;
    	    bottom: -25px;
    	    width: 106px;
	    };
	    @include breakpoint(medium down){
	    	left: -30px;
	    	bottom: 68px;
	    	width: 91px;
	    }
	}
	.fog-1{
		position: absolute;
		top: 19px;
		left: -272px;
		@include breakpoint(xlarge down){
			top: 19px;
		    left: -251px;
		    width: 338px;
		};
		@include breakpoint(medium down){
			top: 12px;
			left: -104px;
			width: 292px;
		};
	}
}
#food-1{
	margin-bottom: 95px;
	@include breakpoint(medium down){
		margin-bottom: 120px;
	};
}
#food-2{
	margin-bottom: 95px;
	@include breakpoint(medium down){
		margin-bottom: 250px;
	};
}
#food-3{
	margin-bottom: 95px;
	@include breakpoint(medium down){
		margin-bottom: 60px;
	};
}
.vegetarian-food-container{
	max-width: 820px;
	margin-bottom: 165px;
	position: relative;
	@include breakpoint(xlarge down){
		max-width: 530px;
		margin-bottom: 107px;
	};
	@include breakpoint(medium down){
		margin-bottom: 75px;
	}
	.title-area{
		@extend .m-vegetarian-title;
		margin-bottom: 30px;
		@include breakpoint(xlarge down){
			margin-bottom: 20px;
		};
	}
	.pic-area{
		margin-bottom: 30px;
		height: 594px;
		position: relative;
		@include breakpoint(xlarge down){
			margin-bottom: 30px;
			height: 384px;
		};
		@include breakpoint(medium down){
			height: 264px;
		    margin: 0 calc(var(--mobileSpace) * -1) 24px;
		};
		.pic-1{
			width: calc(50% - 5px);
			height: calc(50% - 5px);
			position: absolute;
			top: 0;
			left: 0;
			@include breakpoint(xlarge down){
				width: calc(50% - 3px);
				height: calc(50% - 3px);
			};
		}
		.pic-2{
			width: calc(50% - 5px);
			height: calc(50% - 5px);
			position: absolute;
			bottom: 0;
			left: 0;
			@include breakpoint(xlarge down){
				width: calc(50% - 3px);
				height: calc(50% - 3px);
			};
		}
		.pic-3{
			width: calc(50% - 5px);
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			@include breakpoint(xlarge down){
				width: calc(50% - 3px);
			};
		}
	}
	.content{
		font-family: $content-family;
		font-weight: 700;
		font-size: 17px;
		letter-spacing: 1px;
		line-height: 1.6;
		color: $blue;
		@include breakpoint(xlarge down){
			font-size: 13px;
		};
	}
	.cloud-1{
		position: absolute;
		top: 287px;
		right: -84%;
		@include breakpoint(xlarge down){
			top: 200px;
			right: -85%;
			width: 107px;
		};
		@include breakpoint(medium down){
			bottom: 92px;
		    right: auto;
		    left: -20px;
		    width: 84px;
		    top: auto;
		    z-index: 1;
		};
	}
	.cloud-2{
		position: absolute;
		top: 340px;
		right: -94%;
		@include breakpoint(xlarge down){
			top: 240px;
		    right: -96%;
		    width: 57px;
		};
		@include breakpoint(medium down){
			bottom: -30px;
			right: -10px;
			width: 52px;
			top: auto;
		};
	}
	.cloud-3{
		position: absolute;
		top: 38.7%;
		left: -82px;
		z-index: 1;
		@include breakpoint(xlarge down){
			top: 31.7%;
			left: -57px;
			width: 106px;
		};
		@include breakpoint(medium down){
			top: 40.7%;
			left: 42%;
			width: 106px;
		}
	}
}
.vegetarian-deco-1{
	position: absolute;
	top: -225px;
	right: 108px;
	@include breakpoint(xlarge down){
		top: -135px;
		right: 86px;
		width: 430px;
	};
	@include breakpoint(medium down){
		top: -255px;
		right: 14px;
		width: 273px;
	};
}
.vegetarian-deco-2{
	position: absolute;
	top: -84px;
	right: -436px;
	@include breakpoint(xlarge down){
		top: -48px;
	    right: -295px;
	    width: 220px;
	};
	@include breakpoint(medium down){
		top: 0;
	    right: 0;
	    width: 200px;
	    position: relative;
	    margin: 0 0 30px auto;
	}
}
.vegetarian-deco-3{
	position: absolute;
	top: -156px;
	right: -720px;
	@include breakpoint(xlarge down){
		top: -93px;
	    right: -453px;
	    width: 394px;
	};
	@include breakpoint(medium down){
		top: 0;
		right: 0;
		width: 310px;
		position: relative;
		margin: 20px 0 -40px 0;
	};
}
.news-banner-deco{
	position: absolute;
    right: 220px;
    bottom: -366px;
    @include breakpoint(xlarge down){
    	right: 110px;
    	bottom: -239px;
    	width: 287px;
    };
    @include breakpoint(medium down){
    	right: -25px;
	    bottom: -151px;
	    width: 190px;
    };
}
.news-head{
	max-width: 448px;
	margin: 180px auto 90px;
	position: relative;
	@include breakpoint(xlarge down){
		max-width: 315px;
		margin: 110px auto 80px;
	};
	@include breakpoint(medium down){
		margin: 46px auto 230px;
	    padding: 0 var(--mobileSpace);
	    max-width: 100%;
	};
	.news-deco-1{
		position: absolute;
		top: -68px;
	    left: -523px;
	    @include breakpoint(xlarge down){
	    	top: 3px;
	    	left: -359px;
	    	width: 246px;
	    };
	    @include breakpoint(medium down){
	    	top: auto;
	    	left: 20px;
	    	bottom: -198px;
	    	width: 189px;
	    };
	}
	.deco{
		position: absolute;
		top: 0;
		left: -100px;
		@include breakpoint(xlarge down){
			left: -53px;
		};
		@include breakpoint(medium down){
			display: none;
		};
	}
	.head{
		@extend .m-head;
		&:after{
			content: '';
			width: 17px;
			height: 1px;
			background-color: $blue;
			display: block;
			margin: 20px 0 22px;
			@include breakpoint(medium down){
				width: 9px;
				margin: 15px 0 17px;
			};
		}
	}
	.content{
		font-family: $content-family;
		font-weight: 700;
		font-size: 17px;
		letter-spacing: 1px;
		line-height: 1.8;
		color: $blue;
		@include breakpoint(xlarge down){
			font-size: 13px;
		};
	}
	.btn{
		background-color: $blue;
		border-radius: 50px;
		display: inline-block;
		padding: 13px 42px;
		margin-top: 62px;
		.ch{
			font-family: $ch-family;
			font-size: 20px;
			letter-spacing: 3px;
			color: #fff;
			margin-bottom: 6px;
		}
		.en{
			font-family: $content-family;
			font-weight: 700;
			font-size: 12px;
			letter-spacing: 1px;
			color: #fff;
		}
	}
}
.news-catList{
	margin-bottom: 90px;
	@include breakpoint(xlarge down){
		margin-bottom: 56px;
	};
	@include breakpoint(medium down){
		margin-bottom: 40px;
	};
	li{
		font-family: $ch-family;
		font-weight: 700;
		font-size: 18px;
		letter-spacing: 3px;
		color: $blue;
		padding: 0 50px;
		position: relative;
		cursor: pointer;
		@include breakpoint(xlarge down){
			font-size: 15px;
			padding: 0 25px;
		};
		@include breakpoint(medium down){
			font-size: 14px;
			letter-spacing: 2px;
		};
		&:hover, &.current{
			svg .st0{
				stroke-dashoffset: 0;
			}
		}
		svg{
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: -9px;
			z-index: -1;
			@include breakpoint(xlarge down){
				bottom: -7px;
				width: 104px;
				height: auto;
			};
			@include breakpoint(medium down){
				bottom: -5px;
				width: 92px;
			};
		    .st0 {
		        fill: none;
	            stroke: #CECB00;
	            stroke-width: 3;
	            stroke-linecap: round;
	            stroke-linejoin: round;
	            stroke-miterlimit: 10;
	            stroke-dasharray: 805;
	            stroke-dashoffset: 805;
	            transition: all 1s;
		    }
		}
	}
}
.news-reel-top{
	width: 1363px;
	margin: 0 auto -40px;
	z-index: 1;
	position: relative;
	@include breakpoint(xlarge down){
		width: 958px;
	};
	@include breakpoint(medium down){
		width: 96%;
		margin: 0 auto -6px;
	};
}
.news-reel-bottom{
	width: 1363px;
	margin: 0 auto 140px;
	z-index: 1;
	position: relative;
	@include breakpoint(xlarge down){
		width: 958px;
		margin: 0 auto 80px;
	};
	@include breakpoint(medium down){
		width: 96%;
		margin: 0 auto 46px;
	};
}
.newsWrap{
	background: url(../images/news-bg-only-center.svg) center center/100% 100% no-repeat;
	width: 1160px;
	margin: 0 auto -40px;
	padding: 90px 0;
	position: relative;
	display: none;
	@include breakpoint(xlarge down){
		width: 870px;
	};
	@include breakpoint(medium down){
		background: #fff;
		width: 85%;
		margin: 0 auto -15px;
		padding: 27px 0 38px;
		border-left: 2px solid $blue;
		border-right: 2px solid $blue;
	};
}
.newsList{
	max-width: calc((296px + 48px) * 3);
	margin: 0 auto;
	@include breakpoint(xlarge down){
		max-width: calc((230px + 32px) * 3);
	};
	@include breakpoint(medium down){
		max-width: 86%;
	}
	li{
		border-top: 1px solid $blue;
		border-bottom: 1px solid $blue;
		padding: 10px 6px 6px;
		max-width: 296px;
		margin: 0 24px -1px;
		@include breakpoint(xlarge down){
			max-width: 230px;
			margin: 0 16px -1px;
		};
		@include breakpoint(medium down){
			max-width: 100%;
		    margin: 0 0 -1px;
		}
	}
	.pic{
		margin-bottom: 12px;
	}
	.title{
		font-family: $content-family;
		font-weight: 700;
		font-size: 16px;
		letter-spacing: 2px;
		line-height: 1.4;
		color: $blue;
		margin-bottom: 9px;
		@include breakpoint(xlarge down){
			font-size: 13px;
		};
	}
	.content{
		font-family: $content-family;
		font-weight: 700;
		font-size: 13px;
		letter-spacing: 1px;
		line-height: 1.6;
		color: $blue;
		margin-bottom: 48px;
		@include breakpoint(xlarge down){
			font-size: 12px;
			margin-bottom: 35px;
		};
	}
	.date-area{
		.cat{
			font-family: $content-family;
			font-weight: 700;
			font-size: 15px;
			letter-spacing: 2px;
			color: $blue;
			@include breakpoint(xlarge down){
				font-size: 12px;
			};
		}
		.date{
			font-family: $content-family;
			font-weight: 700;
			font-size: 12px;
			letter-spacing: 1px;
			color: $blue;
		}
	}
}
.newsDetailWrap{
	max-width: 1140px;
	margin: 162px auto;
	position: relative;
	@include breakpoint(xlarge down){
		max-width: 680px;
		margin: 116px auto 100px;
	};
	@include breakpoint(medium down){
		margin: 93px auto 120px;
		padding: 0 var(--mobileSpace);
	};
	.head{
		@extend .m-head;
		margin-bottom: 68px;
		@include breakpoint(medium down){
			margin-bottom: 26px;
		}
	}
	.pic-area{
		margin-bottom: 120px;
		position: relative;
		@include breakpoint(xlarge down){
			margin-bottom: 60px;
			width: calc(100% + 84px);
		};
		@include breakpoint(medium down){
			width: 100%;
			margin-bottom: 190px;
		};
		.pic{
			max-width: 811px;
			@include breakpoint(xlarge down){
				max-width: 550px;
			};
			@include breakpoint(medium down){
				margin-bottom: 40px;
			};
		}
		.title{
			font-family: $ch-family;
			font-weight: 600;
			font-size: 30px;
			letter-spacing: 5px;
			line-height: 66px;
			color: $blue;
			writing-mode: tb;
		    text-orientation: upright;
		    background: linear-gradient(to left, transparent 65px, $blue 65px) top right/66px 66px repeat;
		    @include breakpoint(xlarge down){
		    	font-size: 21px;
		    	letter-spacing: 3px;
				line-height: 43px;
			    background: linear-gradient(to left, transparent 42px, $blue 42px) top right/43px 43px repeat;
		    };
		    @include breakpoint(medium down){
		    	font-size: 16px;
		    	letter-spacing: 3px;
		    	height: 166px;
		    };
		}
		.deco-1{
			position: absolute;
			top: -95px;
			left: -126px;
			@include breakpoint(xlarge down){
				top: -71px;
			    left: -85px;
			    width: 130px;
			};
			@include breakpoint(medium down){
				top: auto;
			    left: auto;
			    right: -35px;
			    bottom: 153px;
			    width: 98px;
			};
		}
		.deco-2{
			position: absolute;
			right: 162px;
			bottom: -221px;
			@include breakpoint(xlarge down){
				right: 111px;
				bottom: -127px;
				width: 168px;
			};
			@include breakpoint(medium down){
				right: auto;
				bottom: -162px;
				left: -10px;
				width: 169px;
			};
		}
	}
	.content{
		font-family: $content-family;
		font-weight: 700;
		font-size: 17px;
		letter-spacing: 1px;
		line-height: 1.8;
		color: $blue;
		position: relative;
		@include breakpoint(xlarge down){
			font-size: 13px;
			img{max-width: 550px;}
		};
		@include breakpoint(medium down){
			img{max-width: 100%;}
		}
        .title-area{
			margin-bottom: 40px;
			@include breakpoint(xlarge down){
				margin-bottom: 30px;
			};
			@include breakpoint(medium down){
				margin-bottom: 16px;
			}
			.ch{
				font-family: $ch-family;
				font-weight: 700;
				font-size: 36px;
				letter-spacing: 6px;
				line-height: 1.3;
				color: $blue;
				margin-bottom: 5px;
				@include breakpoint(xlarge down){
					font-size: 24px;
					letter-spacing: 4px;
				};
			}
			.en{
				font-family: $ch-family;
				font-weight: 700;
				font-size: 26px;
				letter-spacing: 3px;
				line-height: 1.3;
				color: $blue;
				@include breakpoint(xlarge down){
					font-size: 17px;
					letter-spacing: 1px;
				};
			}
		}
	}
	.back{
		position: absolute;
		right: 0;
		bottom: -40px;
		cursor: pointer;
		@include breakpoint(xlarge down){
			right: -73px;
			width: 149px;
		};
        @include breakpoint(medium down){
			right: auto;
			left: 50%;
			transform: translateX(-50%);
		    bottom: -76px;
		    width: 130px;
		};
	}
	.cloud-1{
		position: absolute;
		top: 14%;
		left: -16%;
		z-index: 1;
		@include breakpoint(xlarge down){
			top: 11.6%;
			left: -18%;
			width: 91px;
		};
		@include breakpoint(medium down){
			top: 14.6%;
			left: auto;
			right: 16%;
			width: 72px;
		};
	}
	.cloud-2{
		position: absolute;
		top: 14.3%;
	    right: -15.3%;
	    @include breakpoint(xlarge down){
	    	top: 13.3%;
	    	right: -32.3%;
	    	width: 81px;
	    };
		@include breakpoint(medium down){
			top: 3.6%;
		    right: auto;
		    left: 10px;
		    width: 89px;
		    z-index: 1;
		};
	}
	.cloud-3{
		position: absolute;
		top: 25%;
	    right: 11%;
		z-index: 1;
		@include breakpoint(xlarge down){
			top: 24.2%;
			right: 0.6%;
			width: 92px;
		};
		@include breakpoint(medium down){
			top: 26.8%;
			right: auto;
			left: 110px;
			width: 86px;
		};
	}
	.cloud-4{
		position: absolute;
		top: 25.9%;
		right: 7.9%;
		z-index: 1;
		@include breakpoint(xlarge down){
			top: 25%;
			right: -2.4%;
			width: 49px;
		};
		@include breakpoint(medium down){
			top: 27.6%;
			right: auto;
			left: 177px;
			width: 46px;
		};
	}
	.cloud-5{
		position: absolute;
		left: -12%;
		bottom: 15%;
		z-index: 2;
		@include breakpoint(xlarge down){
			left: -14%;
		    bottom: 18%;
		    width: 106px;
		};
		@include breakpoint(medium down){
			display: none;
			// left: 0;
		 //    bottom: 21%;
		 //    width: 95px;
		};
	}
	.cloud-6{
		position: absolute;
		left: -2.3%;
		bottom: 15%;
		z-index: 1;
		@include breakpoint(xlarge down){
			left: -2.7%;
		    bottom: 18%;
		    width: 81px;
		};
		@include breakpoint(medium down){
			display: none;
			// left: 70px;
			// bottom: 21.1%;
			// width: 72px;
		};
	}
	.cloud-7{
		position: absolute;
		right: -2%;
		bottom: 5%;
		@include breakpoint(xlarge down){
			right: -15%;
			bottom: 4.7%;
			width: 143px;
		};
		@include breakpoint(medium down){
			display: none;
			// right: 13px;
			// bottom: -6.4%;
			// width: 130px;
		};
	}
}
.rooms-top-deco{
	text-align: center;
	margin: 200px auto -50px;
	@include breakpoint(xlarge down){
		margin: 113px auto 40px;
		width: 780px;
	};
}
.mobile-rooms-top-deco-1{
	margin: 93px auto 40px;
	padding: 0 30px;
}
.rooms-introWrap{
	position: relative;
	max-width: 820px;
	margin: 0 auto;
	@include breakpoint(xlarge down){
		max-width: 548px;
	};
	@include breakpoint(medium down){
		padding: 0 var(--mobileSpace);
	};
	.head-area{
		max-width: 548px;
		margin: 0 auto 150px;
		position: relative;
		padding-left: 100px;
		@include breakpoint(xlarge down){
			padding-left: 53px;
			margin: 0 auto 90px;
		};
		@include breakpoint(medium down){
			padding-left: 0;
			max-width: 100%;
			margin: 0 0 150px;
		};
		.deco{
			position: absolute;
			top: 0;
			left: 0;
			@include breakpoint(medium down){
				display: none;
			};
		}
		.head{
			@extend .m-head;
		}
		.mobile-deco{
			position: absolute;
			top: -37px;
			right: -46px;
		}
	}
	.pic-area{
		margin-bottom: 147px;
		width: 100%;
		height: 594px;
		position: relative;
		@include breakpoint(xlarge down){
			height: 402px;
			margin-bottom: 72px;
		};
		@include breakpoint(medium down){
			height: 252px;
			margin-bottom: 53px;
		};
		.pic-1{
			width: calc(50% - 5px);
			height: calc(50% - 5px);
			position: absolute;
			top: 0;
			left: 0;
			@include breakpoint(medium down){
				width: calc(50% - 3px);
				height: calc(50% - 3px);
			};
		}
		.pic-2{
			width: calc(50% - 5px);
			height: calc(50% - 5px);
			position: absolute;
			bottom: 0;
			left: 0;
			@include breakpoint(medium down){
				width: calc(50% - 3px);
				height: calc(50% - 3px);
			};
		}
		.pic-3{
			width: calc(50% - 5px);
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			@include breakpoint(medium down){
				width: calc(50% - 3px);
			};
		}
	}
	.content{
		font-family: $content-family;
		font-weight: 700;
		font-size: 17px;
		letter-spacing: 1px;
		line-height: 1.8;
		color: $blue;
		max-width: 562px;
		margin: 0 auto;
		@include breakpoint(xlarge down){
			font-size: 13px;
			max-width: 442px;
		};
	}
	.cloud-1{
		position: absolute;
		top: 16%;
		right: -54%;
		@include breakpoint(xlarge down){
			top: 10%;
		    right: -51%;
		    width: 109px;
		};
		@include breakpoint(medium down){
			display: none;
		}
	}
	.cloud-2{
		position: absolute;
		left: -67%;
	    bottom: 11.5%;
		z-index: 1;
		@include breakpoint(xlarge down){
			left: -63%;
		    bottom: -5.5%;
		    width: 109px;
		};
		@include breakpoint(medium down){
			left: 15px;
			bottom: auto;
			top: 13.8%;
			width: 90px;
		};
	}
	.cloud-3{
		position: absolute;
		left: -53%;
	    bottom: 11.7%;
	    @include breakpoint(xlarge down){
	    	left: -49%;
    	    bottom: -5.3%;
    	    width: 82px;
	    };
		@include breakpoint(medium down){
			left: 72px;
			bottom: auto;
			top: 15.5%;
			width: 70px;
		};
	}
	.cloud-4{
		position: absolute;
		left: -40%;
		bottom: 18%;
		@include breakpoint(xlarge down){
			left: -38%;
		    bottom: 1%;
		    width: 50px;
		};
		@include breakpoint(medium down){
			display: none;
		}
	}
	.cloud-5{
		position: absolute;
		left: -8%;
	    bottom: 28%;
		z-index: 1;
		@include breakpoint(xlarge down){
			left: -6%;
		    bottom: 22%;
			width: 93px;
		};
		@include breakpoint(medium down){
			display: none;
		}
	}
	.cloud-6{
		position: absolute;
		right: -26%;
	    bottom: 42.2%;
		@include breakpoint(xlarge down){
			right: -24%;
		    bottom: 33.8%;
		    width: 93px;
		};
		@include breakpoint(medium down){
			display: none;
		}
	}
	.cloud-7{
		position: absolute;
		right: -30%;
		bottom: 41.6%;
		@include breakpoint(xlarge down){
			right: -28.3%;
		    bottom: 33.4%;
		    width: 50px;
		};
		@include breakpoint(medium down){
			display: none;
		}
	}
	.cloud-8{
		position: absolute;
		right: -51%;
	    bottom: 46%;
	    @include breakpoint(xlarge down){
	    	right: -50%;
	    	bottom: 38%;
	    	width: 147px;
	    };
		@include breakpoint(medium down){
			right: -22px;
			bottom: 22.1%;
			width: 116px;
			z-index: 1;
		};
	}
	.cloud-9{
		position: absolute;
		right: -69%;
		bottom: 54%;
		@include breakpoint(medium down){
			display: none;
		}
	}
}
.rooms-table{
	margin: 120px auto 170px;
	text-align: center;
	@include breakpoint(xlarge down){
		margin: 60px auto 85px;
		width: 110px;
	};
	@include breakpoint(medium down){
		width: 137px;
		margin: 58px auto 90px;
	};
}
.roomsList{
	max-width: 1500px;
	margin: 0 auto;
	@include breakpoint(xlarge down){
		max-width: 1040px;
	};
	li{
		padding: 0 35px 170px;
		position: relative;
		@include breakpoint(xlarge down){
			padding: 0 25px 86px;
		};
		@include breakpoint(medium down){
			padding: 0 var(--mobileSpace) 80px;
		};
		&:hover{
			.pic-area .pic{
				filter: brightness(0.7);
				>img{
				    transform: scale(1.15);
				}
			}
		}
		.pic-area{
			position: relative;
			margin-bottom: 20px;
			.pic{
				overflow: hidden;
				transition: all .5s;
				>img{
					transform: scale(1);
					transition: all 1s;
				}
			}
			.title{
				background-color: #fff;
				position: absolute;
				top: -64px;
				right: 0;
				padding: 18px 14px 18px 16px;
				@include breakpoint(xlarge down){
					padding: 10px;
					top: -37px;
				};
				@include breakpoint(medium down){
					min-width: 61px;
					min-height: 108px;
				}
				span{
					font-family: $ch-family;
					font-weight: 600;
					font-size: 20px;
					letter-spacing: 3px;
					line-height: 1.3;
					color: $blue;
					display: inline-block;
					writing-mode: vertical-lr;
					border-left: 1px solid $blue;
					padding-left: 8px;
					@include breakpoint(xlarge down){
						font-size: 14px;
						padding-left: 4px;
					};
				}
			}
		}
		.content-area{
			height: 193px;
			@include breakpoint(xlarge down){
				height: 152px;
			}
			@include breakpoint(medium down){
				height: 148px;
			}
			.people{
				font-family: $content-family;
				font-weight: 700;
				font-size: 15px;
				letter-spacing: 2px;
				color: #fff;
				background-color: $blue;
				padding: 15px 7px;
				writing-mode: tb;
			    text-orientation: upright;
			    @include breakpoint(xlarge down){
			    	font-size: 12px;
			    	letter-spacing: 1px;
			    };
			    &:before{
			    	content: '';
			    	width: 8px;
			    	height: 8px;
			    	display: inline-block;
			    	vertical-align: middle;
			    	margin-bottom: 10px;
			    	background-color: #fff;
			    	border-radius: 50%;
			    	position: relative;
			    	left: -3px;
			    	@include breakpoint(xlarge down){
			    		width: 6px;
			    		height: 6px;
			    		margin-bottom: 8px;
			    	}
			    }
			}
			.content{
				font-family: $content-family;
				font-weight: 700;
				font-size: 15px;
				letter-spacing: 1px;
				line-height: 43px;
				color: $blue;
				writing-mode: tb;
				text-orientation: upright;
				background: linear-gradient(to left, transparent 42px, $blue 42px) top right/43px 43px repeat;
				@include breakpoint(xlarge down){
					font-size: 12px;
					line-height: 28px;
					background: linear-gradient(to left, transparent 27px, $blue 27px) top right/28px 28px repeat;
				};
			}
		}
	}
}
.rooms-detailWrap{
	position: relative;
	max-width: 1500px;
	margin: 0 auto 130px;
	@include breakpoint(xlarge down){
		max-width: 980px;
		margin-bottom: 80px;
	};
	@include breakpoint(medium down){
		padding: 0 var(--mobileSpace);
		margin-bottom: 125px;
	};
	.mobile-rd-deco{
		width: 72px;
		margin: 32px auto 0;
	}
	.back{
		position: absolute;
		right: 13%;
		bottom: 0;
		cursor: pointer;
		@include breakpoint(xlarge down){
			right: 0;
			width: 149px;
		};
		@include breakpoint(medium down){
			right: auto;
			left: 50%;
			transform: translateX(-50%);
		    bottom: -80px;
		    width: 130px;
		};
	}
}
.mobile-rd-deco-1{
	width: 63px;
	margin: 0 auto 60px;
};
.mobile-rd-deco-2{
    text-align: right;
    margin-top: 20px;
};
.mobile-rd-deco-3{
	position: absolute;
	right: -24px;
	bottom: -17px;
};
.mobile-rd-deco-4{
	position: absolute;
	left: 90px;
	bottom: -68px;
};
.rd-banner-area{
	position: relative;
	margin: 270px auto 155px;
	@include breakpoint(xlarge down){
		margin: 180px auto 80px;
	};
	@include breakpoint(medium down){
		margin: 72px calc(var(--mobileSpace) * -1) 180px;
	};
	.title{
		font-family: $ch-family;
		font-weight: 600;
		font-size: 45px;
		letter-spacing: 8px;
		line-height: 1.3;
		color: $blue;
		position: absolute;
		top: 0;
		right: 0;
		writing-mode: tb;
		text-orientation: upright;
		border-left: 2px solid $blue;
		padding-left: 16px;
		@include breakpoint(xlarge down){
			font-size: 28px;
			right: -14px;
			padding-left: 11px;
		};
	}
	.content{
		font-family: $content-family;
		font-weight: 600;
		font-size: 15px;
		letter-spacing: 2px;
		line-height: 43px;
		color: $blue;
		writing-mode: tb;
		text-orientation: upright;
		background: linear-gradient(to left, transparent 42px, $blue 42px) top right/43px 43px repeat;
		position: absolute;
		right: 0;
		bottom: calc(100% + 20px);
		height: 193px;
	}
	.rd-cloud-1{
		position: absolute;
		left: -96px;
		bottom: 169px;
		@include breakpoint(xlarge down){
			left: -39px;
		    bottom: 119px;
		    width: 91px;
		};
		@include breakpoint(medium down){
			left: -8px;
			bottom: -67px;
			width: 77px;
		};
	}
	.rd-cloud-2{
		position: absolute;
		left: 0;
	    bottom: 109px;
	    z-index: 1;
	    @include breakpoint(xlarge down){
	    	left: 33px;
    	    bottom: 74px;
    	    width: 87px;
	    };
	    @include breakpoint(medium down){
	    	left: 60px;
	    	bottom: -97px;
	    	width: 74px;
	    };
	}
	.rd-cloud-3{
		position: absolute;
		left: 89px;
		bottom: 114px;
		@include breakpoint(xlarge down){
			left: 94px;
		    bottom: 74px;
		    width: 66px;
		};
		@include breakpoint(medium down){
			left: 116px;
			bottom: -96px;
			width: 56px;
		};
	}
	.rd-cloud-4{
		position: absolute;
		top: 46%;
	    right: 120px;
	    @include breakpoint(xlarge down){
	    	top: 46%;
	    	right: 95px;
	    	width: 91px;
	    };
		@include breakpoint(medium down){
			top: 39%;
		    right: -9px;
		    width: 79px;
		};
	}
	.rd-cloud-5{
		position: absolute;
		top: 51%;
		right: 88px;
		@include breakpoint(xlarge down){
			top: 50%;
		    right: 68px;
		    width: 49px;
		};
		@include breakpoint(medium down){
			top: 43%;
			right: -35px;
			width: 43px;
		};
	}
}
.mobile-article-area{
	margin-top: 45px;
	padding: 0 var(--mobileSpace);
	height: 193px;
	.mobile-title{
		font-family: $ch-family;
		font-weight: 600;
		font-size: 20px;
		letter-spacing: 3px;
		line-height: 1.3;
		color: $blue;
		writing-mode: tb;
		text-orientation: upright;
		border-left: 2px solid $blue;
		padding-left: 8px;
		margin-left: 20px;
	}
	.mobile-content{
		font-family: $content-family;
		font-weight: 600;
		font-size: 12px;
		letter-spacing: 1px;
		line-height: 35px;
		color: $blue;
		writing-mode: tb;
		text-orientation: upright;
		background: linear-gradient(to left, transparent 34px, $blue 34px) top right/35px 35px repeat;
	}
}
.rd-banner-slider-wrap{
	position: relative;
	max-width: 1260px;
	@include breakpoint(xlarge down){
		max-width: 820px;
	};
	/* fade css */
	.flickity-slider {
		transform: none !important;
	}
	.rd-banner-slider{
		li{
			width: 100%;
			/* fade css */
			left: 0 !important;
			opacity: 0;
			transition: opacity 0.5s ease-in-out;
			z-index: -1;
			&.is-selected {
				opacity: 1;
				z-index: 0
			}
		}
	}
	/* position dots in carousel */
	.flickity-page-dots {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 12px;
	}
	/* white circles */
	.flickity-page-dots .dot {
		display: inline-block;
		vertical-align: middle;
		width: 6px;
		height: 6px;
		background-color: #fff;
		border-radius: 50%;
		opacity: .5;
		@include mr(8px);
		&.current{
			opacity: 1;
		}
	}
	/* fill-in selected dot */
	.flickity-page-dots .dot.is-selected {
		opacity: 1;
	}
}
// .rd-dots{
// 	position: absolute;
// 	left: 50%;
// 	transform: translateX(-50%);
// 	bottom: 12px;
// 	a{
// 		display: inline-block;
// 		vertical-align: middle;
// 		width: 6px;
// 		height: 6px;
// 		background-color: #fff;
// 		border-radius: 50%;
// 		opacity: .5;
// 		@include mr(8px);
// 		&.current{
// 			opacity: 1;
// 		}
// 	}
// }
.rd-content{
	font-family: $content-family;
	font-weight: 700;
	font-size: 17px;
	letter-spacing: 1px;
	line-height: 1.8;
	color: $blue;
	margin-bottom: 104px;
	@include breakpoint(xlarge down){
		font-size: 13px;
		line-height: 1.7;
		margin-bottom: 64px;
	};
	>.cell{
		max-width: 450px;
		margin-right: 50px;
		@include breakpoint(xlarge down){
			max-width: 400px;
		}
		@include breakpoint(medium down){
			margin-bottom: 30px;
		};
	}
}
.rd-detail{
	margin-bottom: 80px;
	@include breakpoint(medium down){
		margin-bottom: 51px;
	};
	.head{
		@include m-rd-head;
		margin-bottom: 10px;
	}
}
.rd-detailList{
	li{
		position: relative;
		margin-right: 7px;
		width: 24.8%;
		@include breakpoint(medium down){
			width: 50%;
		};
		&:hover{
			&:after{opacity: 1;}
		}
		&:after{
			content: '';
			background: url(../images/view-more.svg) center center no-repeat, linear-gradient(rgba(#000,.5), rgba(#000,.5));
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			opacity: 0;
			transition: all .5s;
			pointer-events: none;
			@include breakpoint(xlarge down){
			    background-size: 30px;
			};
		}
	}
	/* reset */
	.flickity-button {
		background-color: transparent;
		&:hover {
		    background: transparent;
		}
		&:focus {
		    outline: none;
  			box-shadow: none;
		}
		&:active {
		    opacity: 1;
		}
		&:disabled {
		    opacity: .3;
		    cursor: auto;
		    pointer-events: none
		}
	}
	/* previous & next buttons */
	.flickity-prev-next-button {
		width: 100px;
		height: 100px;
		@include breakpoint(xlarge down){
			width: 70px;
		    height: 70px;
		};
		&.previous{
			left: 20px;
			@include breakpoint(xlarge down){
				left: 10px;
			};
		}
		&.next{
			right: 20px;
			@include breakpoint(xlarge down){
				right: 10px;
			};
		}
		/* svg */
		.flickity-button-icon {
			fill: #fff;
			left: 0%;
			top: 0%;
			width: 100%;
			height: 100%;
		}
	}
}
.rd-amenities-wrap{
	position: relative;
	.travel-container{
		margin-right: 100px;
		@include breakpoint(xlarge down){
			margin-right: 75px;
		};
	}
	.apartment-container{
		margin-top: 50px;
		@include breakpoint(medium down){
			margin-top: 0;
		}
		.deco{
			margin-bottom: 80px;
		}
	}
	.deco-1{
		position: absolute;
		right: -4%;
		bottom: 10%;
		@include breakpoint(xlarge down){
			right: -1%;
		    bottom: 8%;
		    width: 87px;
		};
	}
	.deco-2{
		position: absolute;
		left: -140px;
		bottom: 34%;
		@include breakpoint(xlarge down){
			left: -108px;
			bottom: 19%;
			width: 55px;
		};
	}
}
.rd-structure{
	.head{
		@include m-rd-head;
		border-bottom: 1px solid $blue;
		padding-bottom: 10px;
		margin-bottom: 35px;
		position: relative;
		@include breakpoint(medium down){
			padding-bottom: 14px;
			margin-bottom: 9px;
		};
		&.is-open{
			.plus:before{
				transform: translate(-50%,-50%) rotate(180deg);
				opacity: 0;
			}
			.plus:after{
				transform: translate(-50%,-50%) rotate(270deg);
			}
		}
		.plus{
			display: inline-block;
			vertical-align: middle;
			margin-right: 5px;
			position: absolute;
			top: 13px;
			right: 0;
			width: 17px;
			height: 17px;
			&:before, &:after{
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%) rotate(0deg);
				background-color: $blue;
				transition: all 1s;
			}
			&:before{
				width: 100%;
				height: 2px;
			}
			&:after{
				width: 2px;
				height: 100%;
			}
		}
	}
}
.structureList{
	@include breakpoint(medium down){
		display: none;
		margin-bottom: 50px;
	}
	li{
		@include mb(30px);
		@include breakpoint(medium down){
			font-size: 14px;
		};
	}
	.title{
		font-family: $content-family;
		font-weight: 700;
		font-size: 16px;
		letter-spacing: 1px;
		line-height: 1.6;
		color: $blue;
		width: 144px;
		letter-spacing: 7px;
		@include breakpoint(xlarge down){
			font-size: 13px;
			letter-spacing: 3px;
			width: 100px;
		};
		@include breakpoint(medium down){
			width: 100%;
			border-bottom: 1px solid $blue;
			padding-bottom: 6px;
		    margin-bottom: 14px;
			text-align: right;
		};
	}
	.content{
		font-family: $content-family;
		font-weight: 700;
		font-size: 14px;
		letter-spacing: 1px;
		line-height: 1.6;
		color: $blue;
		@include breakpoint(xlarge down){
			font-size: 13px;
		};
	}
	.payList{
		span{
			@include mr(20px);
		}
	}
}
// .rd-price{
// 	.head{
// 		@include m-rd-head;
// 		border-bottom: 1px solid $blue;
// 		padding-bottom: 10px;
// 		margin-bottom: 35px;
// 		@include breakpoint(medium down){
// 			padding-bottom: 15px;
// 		    margin-bottom: 16px;
// 		};
// 	}
// }
// .priceList{
// 	li{
// 		font-family: $content-family;
// 		font-weight: 700;
// 		font-size: 16px;
// 		letter-spacing: 1px;
// 		line-height: 1.6;
// 		color: $blue;
// 		@include mb(14px);
// 		@include breakpoint(medium down){
// 			font-size: 14px;
// 			@include mb(6px);
// 		};
// 		&:nth-child(1){
// 			letter-spacing: 7px;
// 			@include breakpoint(medium down){
// 				letter-spacing: 5px;
// 			};
// 		}
// 	}
// 	.day{
// 		width: 170px;
// 		letter-spacing: 7px;
// 		@include breakpoint(medium down){
// 			width: 120px;
// 			letter-spacing: 5px;
// 		};
// 	}
// 	.price{
// 		width: 170px;
// 		@include breakpoint(medium down){
// 			width: 120px;
// 		};
// 	}
// 	.sale{}
// }
.rd-amenities{
	margin-bottom: 180px;
	@include breakpoint(xlarge down){
		margin-bottom: 70px;
	};
	@include breakpoint(medium down){
		margin-bottom: 20px;
	};
	.head{
		@include m-rd-head;
		border-bottom: 1px solid $blue;
		padding-bottom: 10px;
		margin-bottom: 30px;
		position: relative;
		@include breakpoint(medium down){
		    padding-bottom: 14px;
		    margin-bottom: 0;
		};
		&.is-open{
			.plus:before{
				transform: translate(-50%,-50%) rotate(180deg);
				opacity: 0;
			}
			.plus:after{
				transform: translate(-50%,-50%) rotate(270deg);
			}
		}
		.plus{
			display: inline-block;
			vertical-align: middle;
			margin-right: 5px;
			position: absolute;
			top: 13px;
			right: 0;
			width: 17px;
			height: 17px;
			&:before, &:after{
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%) rotate(0deg);
				background-color: $blue;
				transition: all 1s;
			}
			&:before{
				width: 100%;
				height: 2px;
			}
			&:after{
				width: 2px;
				height: 100%;
			}
		}
	}
}
.amenitiesList{
	@include breakpoint(medium down){
		display: none;
		margin-bottom: 50px;
	}
	li{
		position: relative;
		@include mb(23px);
		@include breakpoint(xlarge down){
			@include mb(12px);
		};
	}
	.title{
		font-family: $content-family;
		font-weight: 700;
		font-size: 16px;
		letter-spacing: 1px;
		line-height: 1.6;
		color: $blue;
		width: 144px;
		letter-spacing: 4px;
		position: relative;
		top: 6px;
		@include breakpoint(xlarge down){
			font-size: 13px;
			letter-spacing: 3px;
			width: 90px;
			top: 10px;
		};
		@include breakpoint(medium down){
			width: 100%;
			border-bottom: 1px solid $blue;
			padding-bottom: 6px;
		    margin-bottom: 20px;
			text-align: right;
		};
	}
	.icon{
		span{
			text-align: center;
			display: inline-block;
			vertical-align: top;
		    margin: 0 6px 18px;
		    width: 90px;
		    @include breakpoint(xlarge down){
		    	margin: 0 3px 17px;
		    	width: 80px;
		    };
		    @include breakpoint(medium down){
		    	margin: 0 3px 6px;
		    	width: 23%;
		    }
		}
		.pic{
			height: 50px;
			margin-bottom: 5px;
			@include flex;
			@include flex-align(center, middle);
			@include breakpoint(medium down){
				height: 42px;
				width: 40px;
				margin: 0 auto 5px;
			};
			img{
				max-height: 100%;
			}
		}
		.name{
			font-family: $content-family;
			font-weight: 700;
			font-size: 13px;
			letter-spacing: 1px;
			line-height: 1.3;
			color: $blue;
			margin: 0 auto;
			@include breakpoint(xlarge down){
				font-size: 12px;
			};
		}
	}
}
.notice{
	z-index: 19;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(#000, .75);
	cursor: pointer;
	.hand-area{
		margin-bottom: 58px;
		position: relative;
		.drag-line{
			width: 158px;
			height: 31px;
			background: url(../images/drag-line.svg) left top/158px 31px no-repeat;
			animation: dragline infinite 2s;
		}
		.hand{
			position: absolute;
			top: 100%;
			left: 0;
			animation: movinghand infinite 2s;
		}
		img{
			max-width: initial;
		}
	}
	.text{
		font-family: $ch-family;
		font-weight: 600;
		font-size: 14px;
		letter-spacing: 2px;
		color: #fff;
	}
}
@keyframes dragline{
	0%{
		width: 0;
		margin-right: 158px;
	}
	100%{
		width: 158px;
		margin-right: 0;
	}
}
@keyframes movinghand{
	0%{
		left: 0;
	}
	100%{
		left: 93%;
	}
}
.notice-2{
	z-index: 19;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(#000, .75);
	cursor: pointer;
	display: none;
	.pic{
		position: absolute;
		left: 27px;
	    bottom: 11px;
	}
	.text{
		font-family: $ch-family;
		font-weight: 600;
		font-size: 14px;
		letter-spacing: 2px;
		color: #fff;
		position: absolute;
	    left: 82px;
	    bottom: 90px;
	}
}
.mobile-topmenuWrap{
	z-index: 49;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 72px;
	// background: url(../images/mobile-topmenu-bg.svg) 0 0/100vw 100vh no-repeat, #fff;
	background-color: $blue;
	padding: 17px var(--mobileSpace);
	transition: all 1s;
    overflow-y: auto;
	&.is-open{
		height: 100vh;
	}
}
.mobile-top-fixed-area{
	.logo{}
	.hamburger-container{}
	.book-icon{
		margin-right: 20px;
	}
	.hamburger-area{
		position: relative;
		background: url(../images/hamburger-bg.svg) center center/cover no-repeat;
		width: 38px;
		height: 38px;
		.hamburger{
			width: 20px;
			height: 12px;
			position: relative;
			top: 12px;
			left: 9px;
			&.is-show{
				.item1{
					top: 50%;
					transform: rotate(45deg);
					transition: top 0.4s ,transform 0.4s 0.4s;
				}
				.item2{
					opacity: 0;
				}
				.item3{
					top: 50%;
					transform: rotate(-45deg);
					transition: top 0.4s ,transform 0.4s 0.4s;
				}
			}
			span{
				display: block;
				width: 100%;
				height: 1px;
				background-color: #fff;
			}
			.item1{
				position: absolute;
				top: 0;
				transition:transform 0.4s ,top 0.4s 0.4s;
			}
			.item2{
				position: absolute;
				top: 50%;
				transition: opacity 0.8s;
			}
			.item3{
				position: absolute;
				top: 100%;
				transition:transform 0.4s ,top 0.4s 0.4s;
			}
		}
	}
}
.mobile-topmenu-container{
	margin-top: 60px;
	margin-left: -20px;
	margin-right: -20px;
	padding: 0 20px;
	overflow: hidden;
	display: none;
	.mobile-topmenuList{
		a{
			@include mb(20px);
		}
		.ch{
			font-family: $content-family;
			font-size: 14px;
			letter-spacing: 3px;
			color: #fff;
		}
		.en{
			font-family: $content-family;
			font-size: 21px;
			letter-spacing: 1px;
			color: #fff;
		}
	}
	.shareList{
		margin-top: 51px;
		a{
			@include mr(20px);
		}
	}
}
.kaifengWrap{
	max-width: 1400px;
	margin: 0 auto;
	position: relative;
	@include breakpoint(xlarge down){
		max-width: 930px;
	};
	@include breakpoint(medium down){
		padding: 0 var(--mobileSpace);
	}
	.ka-deco-1{
		position: absolute;
		top: -183px;
		right: -78px;
		@include breakpoint(xlarge down){
			top: -114px;
		    right: -60px;
		    width: 242px;
		}
		@include breakpoint(medium down){
			top: -87px;
		    right: -10px;
		    width: 198px;
		}
	}
	.ka-cloud-1{
		position: absolute;
		top: 74px;
		right: -96px;
		z-index: 2;
		@include breakpoint(xlarge down){
			top: 81px;
			right: -76px;
			width: 119px;
		}
		@include breakpoint(medium down){
			top: 70px;
		    right: 0;
		    width: 88px;
		}
	}
	.ka-cloud-2{
		position: absolute;
		top: 92px;
		right: -166px;
		@include breakpoint(xlarge down){
			top: 91px;
		    right: -130px;
		    width: 90px;
		}
		@include breakpoint(medium down){
			top: 78px;
			right: -40px;
			width: 66px;
		}
	}
	.ka-cloud-3{
		position: absolute;
		top: 24px;
		right: -223px;
		@include breakpoint(xlarge down){
			top: 47px;
		    right: -148px;
		    width: 54px;
		}
	}
}
.kaifeng-head{
	padding-left: 100px;
	margin: 57px 0 83px 100px;
	position: relative;
	@include breakpoint(xlarge down){
		padding-left: 53px;
	    margin: 33px 0 58px 30px;
	};
	@include breakpoint(medium down){
		padding-left: 0;
		margin: 22px 0 38px 0;
	}
	.deco{
		position: absolute;
		top: 0;
		left: 0;
		@include breakpoint(medium down){
			display: none;
		}
	}
	.head{
		@extend .m-head;
	}
}
.kaifeng-log{
    max-width: 640px;
	margin: 0 auto 110px;
	@include breakpoint(xlarge down){
		margin-bottom: 130px;
	};
	@include breakpoint(medium down){
		margin-bottom: 96px;
	}
	.content{
		font-family: $ch-family;
		font-weight: 600;
		font-size: 17px;
		letter-spacing: 2px;
		line-height: 2;
		color: $blue;
		position: relative;
		left: 135px;
		@include breakpoint(xlarge down){
			font-size: 13px;
		};
		@include breakpoint(medium down){
			left: 0;
			margin-bottom: 21px;
		}
	}
	.pic{
		@include breakpoint(xlarge down){
			width: 172px;
		};
	}
}
.m-kaifeng-head{
	margin-bottom: 120px;
	position: relative;
	z-index: 9;
	@include breakpoint(xlarge down){
		margin-bottom: 53px;
	};
	@include breakpoint(medium down){
		margin-bottom: 30px;
	}
	.ch{
		font-family: $ch-family;
		font-weight: 700;
		font-size: 36px;
		letter-spacing: 6px;
		color: $blue;
		margin-bottom: 9px;
		@include breakpoint(xlarge down){
			font-size: 24px;
			letter-spacing: 4px;
			margin-bottom: 7px;
		};
		@include breakpoint(medium down){
			font-size: 20px;
			letter-spacing: 3px;
			margin-bottom: 6px;
		}
	}
	.en{
		font-family: $ch-family;
		font-weight: 700;
		font-size: 26px;
		letter-spacing: 3px;
		line-height: 1.2;
		color: $blue;
		@include breakpoint(xlarge down){
			font-size: 18px;
			letter-spacing: 2px;
		};
		@include breakpoint(medium down){
			font-size: 14px;
			letter-spacing: 1px;
		}
	}
}
.kaifeng-history{
	position: relative;
	margin-bottom: 262px;
	@include breakpoint(xlarge down){
		margin-bottom: 208px;
	}
	@include breakpoint(medium down){
		margin-bottom: 118px;
	}
	.head-area{
		@extend .m-kaifeng-head;
		margin-left: 16.3%;
		@include breakpoint(medium down){
			margin-left: 0;
		}
	}
	.wrap-1{
		.article-area{
			position: relative;
			.content-1{
				margin-bottom: 57px;
				@include breakpoint(xlarge down){
					margin-bottom: 31px;
				};
				@include breakpoint(medium down){
					margin-bottom: 22px;
				}
				p{
					@extend .m-about-inline;
				}
			}
			.pic{
				@include breakpoint(xlarge down){
					max-width: 523px;
				}
			}
			.content-2{
				position: absolute;
				right: -135px;
				bottom: -34px;
				@include breakpoint(xlarge down){
					right: -129px;
				    bottom: -37px;
				}
				@include breakpoint(medium down){
					right: -10px;
					bottom: -39px;
				}
				p{
					@extend .m-about-inline;
				}
			}
		}
		.pic-area{
			margin: 70px 0 0 176px;
			@include breakpoint(xlarge down){
				margin: 63px 0 0 123px;
			    max-width: 264px;
			}
			@include breakpoint(medium down){
				margin: 69px -20px 0 auto;
				max-width: 200px;
			}
		}
	}
	.fog-1{
		position: absolute;
		top: -70px;
		left: 80px;
		@include breakpoint(xlarge down){
			top: -50px;
			left: 60px;
			width: 275px;
		}
		@include breakpoint(medium down){
			left: -30px;
		}
	}
	.cloud-1{
		z-index: 1;
		position: absolute;
		left: -105px;
	    bottom: 8px;
	    @include breakpoint(xlarge down){
	    	left: -55px;
	    	bottom: 3px;
	    	width: 91px;
	    }
	    @include breakpoint(medium down){
	    	left: auto;
    	    bottom: auto;
    	    width: 61px;
    	    right: -6px;
    	    top: 234px;
	    }
	}
	.cloud-2{
		z-index: 1;
		position: absolute;
		left: -3px;
		bottom: 3px;
		@include breakpoint(xlarge down){
			left: 16px;
			bottom: 1px;
			width: 49px;
		}
		@include breakpoint(medium down){
			left: auto;
		    bottom: auto;
		    width: 33px;
		    top: 243px;
		    right: -30px;
		}
	}
	.cloud-3{
		z-index: 1;
		position: absolute;
		left: -50px;
	    bottom: -28px;
	    @include breakpoint(xlarge down){
	    	left: -27px;
	    	bottom: -19px;
	    	width: 87px;
	    }
	    @include breakpoint(medium down){
	    	left: -27px;
	    	bottom: 114px;
	    	width: 87px;
	    }
	}
	.cloud-4{
		z-index: 1;
		position: absolute;
		left: 40px;
	    bottom: -22px;
	    @include breakpoint(xlarge down){
	    	left: 37px;
	    	bottom: -15px;
	    	width: 66px;
	    }
	    @include breakpoint(medium down){
	    	left: 37px;
	    	bottom: 115px;
	    	width: 66px;
	    }
	}
	.cloud-5{
		z-index: 1;
		position: absolute;
		top: 386px;
	    right: -100px;
	    @include breakpoint(xlarge down){
	    	top: 247px;
	    	right: -50px;
	    	width: 91px;
	    }
	    @include breakpoint(medium down){
	    	top: auto;
	    	right: 110px;
	    	width: 86px;
	    	bottom: -17px;
	    	z-index: 1;
	    }
	}
}
.kaifeng-honor{
	position: relative;
	margin-bottom: 220px;
	@include breakpoint(xlarge down){
		margin-bottom: 140px;
	}
	@include breakpoint(medium down){
		margin-bottom: 165px;
	}
	.head-area{
		@extend .m-kaifeng-head;
		margin-left: 37.6%;
		@include breakpoint(xlarge down){
			margin-left: 39.3%;
		}
		@include breakpoint(medium down){
			margin-left: 0;
		}
	}
	.wrap-1{
		margin-bottom: 100px;
		position: relative;
		@include breakpoint(xlarge down){
			margin-bottom: 40px;
		}
		.col-left{
			margin-right: 20px;
			@include breakpoint(xlarge down){
				max-width: 350px;
				margin-right: 26px;
			}
			.content{
				@extend .m-about-content;
				position: relative;
				z-index: 1;
				margin-bottom: 80px;
				@include breakpoint(xlarge down){
					margin-bottom: 50px;
				}
				@include breakpoint(medium down){
					margin-bottom: 36px;
				}
			}
			.pic{
				@include breakpoint(xlarge down){
					max-width: 243px;
				}
				@include breakpoint(medium down){
					max-width: 188px;
				}
			}
			.mobile-content{
				margin: 100px 0 0 8px;
				p{
					@extend .m-about-inline;
				}
			}
		}
		.col-right{
			.pic{
				margin-bottom: 80px;
				@include breakpoint(xlarge down){
					width: 623px;
				}
				@include breakpoint(medium down){
					width: calc(100% + var(--mobileSpace) * 2);
				    margin-left: calc(var(--mobileSpace) * -1);
				    margin-bottom: 22px;
				}
			}
			.content{
				@extend .m-about-inline;
				position: relative;
			    left: 44%;
			}
		}
		.fog-1{
			position: absolute;
			top: -240px;
			left: 28%;
			@include breakpoint(xlarge down){
				top: -190px;
				left: 25%;
				width: 286px;
			}
			@include breakpoint(medium down){
				top: -138px;
			    left: -37px;
			    width: 206px;
			}
		}
		.cloud-1{
			position: absolute;
			top: 30%;
			right: -1%;
			@include breakpoint(xlarge down){
				top: 24.1%;
				right: -11%;
				width: 91px;
			}
			@include breakpoint(medium down){
				top: 23.1%;
				right: -6%;
				width: 80px;
			}
		}
		.cloud-2{
			position: absolute;
			top: 33.9%;
		    right: -4%;
		    @include breakpoint(xlarge down){
		    	top: 27%;
		    	right: -13%;
		    	width: 49px;
		    }
		    @include breakpoint(medium down){
		    	top: 24.9%;
		    	right: -13%;
		    	width: 41px;
		    }
		}
		.cloud-3{
			position: absolute;
			left: 28%;
		    bottom: 22%;
		    @include breakpoint(xlarge down){
		    	bottom: 29%;
	    	    width: 91px;
		    }
		    @include breakpoint(medium down){
		    	bottom: 24%;
	    	    left: 45%;
		    }
		}
		.cloud-4{
			position: absolute;
			left: 36%;
			bottom: 14%;
			z-index: 1;
			@include breakpoint(xlarge down){
				bottom: 23%;
			    width: 87px;
			}
			@include breakpoint(medium down){
				bottom: 20%;
				left: 67%;
			}
		}
		.cloud-5{
			position: absolute;
			left: 42.2%;
			bottom: 14%;
			@include breakpoint(xlarge down){
				bottom: 23%;
				width: 66px;
			}
			@include breakpoint(medium down){
				bottom: 20%;
				left: 88%;
			}
		}
	}
	.wrap-2{
		position: relative;
		.pic{
			text-align: right;
		    margin-right: -380px;
		    @include breakpoint(xlarge down){
	    	    margin-right: -244px;
	    	    img{max-width: 704px;}
		    }
		    @include breakpoint(medium down){
		    	margin-right: 0;
		    	margin-left: -54px;
	    	    img{max-width: 464px;}
		    }
		}
		.content{
			position: absolute;
			left: 30.8%;
			bottom: 40px;
			@include breakpoint(xlarge down){
				left: 10.8%;
			    bottom: 87px;
			}
			@include breakpoint(medium down){
				left: 0;
				bottom: 0;
				position: relative;
				margin: 40px 0 0;
			}
			p{
				@extend .m-about-inline;
			}
		}
		.cloud-1{
			position: absolute;
			top: 5%;
			right: -8%;
			@include breakpoint(xlarge down){
				top: 6%;
				right: -9%;
				width: 40px;
			}
			@include breakpoint(medium down){
				top: 1%;
				right: 4%;
				width: 26px;
			}
		}
		.cloud-2{
			position: absolute;
			top: -3%;
			right: -18%;
			@include breakpoint(xlarge down){
				top: -4%;
			    right: -17%;
			    width: 77px;
			}
			@include breakpoint(medium down){
				top: -4%;
			    right: -13%;
			    width: 51px;
			}
		}
		.cloud-3{
			position: absolute;
			left: 20%;
			bottom: -13%;
			@include breakpoint(xlarge down){
				left: 19%;
				bottom: 9%;
				width: 111px;
			}
			@include breakpoint(medium down){
				left: 19%;
				bottom: -18%;
				width: 91px;
			}
		}
		.cloud-4{
			position: absolute;
			left: 28.7%;
		    bottom: -13.4%;
		    @include breakpoint(xlarge down){
		    	left: 28%;
	    	    bottom: 8.6%;
	    	    width: 59px;
		    }
		    @include breakpoint(medium down){
		    	left: 42%;
	    	    bottom: -18.4%;
	    	    width: 54px;
		    }
		}
	}
}
.kaifeng-river{
	position: relative;
	margin-bottom: 260px;
	@include breakpoint(xlarge down){
		margin-bottom: 194px;
	}
	@include breakpoint(medium down){
		margin-bottom: 154px;
	}
	.head-area{
		@extend .m-kaifeng-head;
	}
	.wrap-1{
		margin-bottom: 100px;
		position: relative;
		.pic-area{
			margin-right: 50px;
			@include breakpoint(xlarge down){
				margin-right: 37px;
				max-width: 600px;
			}
			.content{
				@extend .m-about-inline;
			}
			.pic{
				margin-top: 40px;
				@include breakpoint(xlarge down){
					margin-top: 22px;
				}
				@include breakpoint(medium down){
					margin: 22px calc(var(--mobileSpace) * -1) 0;
				}
			}
		}
		.article-area{
			@include breakpoint(xlarge down){
				position: relative;
			    top: 55px;
			}
			.title{
				@extend .m-about-content;
				margin-bottom: 100px;
				@include breakpoint(xlarge down){
					margin-bottom: 30px;
					width: 440px;
				}
			}
			.content{
				@extend .m-about-content;
				@include breakpoint(xlarge down){
					width: 440px;
				}
			}
		}
	}
	.fog-1{
		position: absolute;
		top: -50px;
	    left: -130px;
	    @include breakpoint(xlarge down){
	    	left: -90px;
    	    width: 275px;
	    }
	    @include breakpoint(medium down){
	    	left: -40px;
	    	width: 315px;
	    }
	}
	.cloud-1{
		z-index: 1;
		position: absolute;
		left: -105px;
	    bottom: 20px;
	    @include breakpoint(xlarge down){
	    	left: -55px;
	    	bottom: 3px;
	    	width: 91px;
	    }
	    @include breakpoint(medium down){
    	    bottom: 34%;
	    }
	}
	.cloud-2{
		z-index: 1;
		position: absolute;
		left: -3px;
		bottom: 15px;
		@include breakpoint(xlarge down){
			left: 16px;
			bottom: 1px;
			width: 49px;
		}
		@include breakpoint(medium down){
			bottom: 33.9%;
		}
	}
	.cloud-3{
		z-index: 1;
		position: absolute;
		left: -50px;
	    bottom: -16px;
	    @include breakpoint(xlarge down){
	    	left: -27px;
	    	bottom: -19px;
	    	width: 87px;
	    }
	    @include breakpoint(medium down){
	    	bottom: 30.6%;
	    }
	}
	.cloud-4{
		z-index: 1;
		position: absolute;
		left: 40px;
	    bottom: -10px;
	    @include breakpoint(xlarge down){
	    	left: 37px;
	    	bottom: -15px;
	    	width: 66px;
	    }
	    @include breakpoint(medium down){
	    	bottom: 31%;
	    }
	}
}
.kaifeng-hundred{
	position: relative;
	margin-bottom: 242px;
	@include breakpoint(xlarge down){
		margin-bottom: 182px;
	}
	@include breakpoint(medium down){
		margin-bottom: 130px;
	}
	.head-area{
		@extend .m-kaifeng-head;
		margin-bottom: 60px;
		@include breakpoint(xlarge down){
			margin-bottom: 24px;
		}
		@include breakpoint(medium down){
			margin-bottom: 50px;
		}
	}
	.wrap-1{
		margin-bottom: 100px;
		position: relative;
		.pic-area{
			margin: 0 120px 0 77px;
			position: relative;
			@include breakpoint(xlarge down){
				margin: 0 81px 0 55px;
			    max-width: 287px;
			}
			@include breakpoint(medium down){
				margin: 0 0 42px 0;
			    max-width: 100%;
			}
			.pic{
				@include breakpoint(medium down){
					margin: 0 calc(var(--mobileSpace) * -1);
				}
			}
			.content{
				position: absolute;
				top: -15px;
			    right: -327px;
			    @include breakpoint(xlarge down){
			    	top: -29px;
			    	right: -286px;
			    }
			    @include breakpoint(medium down){
			    	top: 0;
		    	    right: 0;
		    	    position: relative;
		    	    margin: -12px 0 0 6px;
			    }
				p{
					@extend .m-about-inline;
				}
			}
		}
		.article-area{
			@include breakpoint(xlarge down){
				max-width: 367px;
			}
			.pic{
				margin-bottom: 100px;
				@include breakpoint(xlarge down){
					margin-bottom: 50px;
				}
				@include breakpoint(medium down){
					margin: 0 0 30px calc(var(--mobileSpace) * -1);
					width: 200px;
				}
			}
			.content{
				@extend .m-about-content;
				@include breakpoint(medium down){
					padding-left: 41%;
				}
			}
		}
	}
	.fog-1{
		position: absolute;
		top: -70px;
	    left: -10px;
	    @include breakpoint(xlarge down){
	    	top: -61px;
	    	left: 7px;
	    	width: 417px;
	    }
	    @include breakpoint(medium down){
	    	top: -33px;
	    	left: -53px;
	    	width: 399px;
	    	z-index: 1;
	    	opacity: .6;
	    }
	}
	.cloud-1{
		z-index: 1;
		position: absolute;
		right: 115px;
		bottom: 213px;
		@include breakpoint(xlarge down){
			right: 85px;
			bottom: 145px;
			width: 91px;
		}
		@include breakpoint(medium down){
			right: 0%;
		    bottom: auto;
		    top: 48%;
		}
	}
	.cloud-2{
		z-index: 1;
		position: absolute;
		right: 79px;
		bottom: 209px;
		@include breakpoint(xlarge down){
			right: 56px;
		    bottom: 145px;
			width: 49px;
		}
		@include breakpoint(medium down){
			right: -9%;
			bottom: auto;
			top: 50.5%;
		}
	}
	.cloud-3{
		z-index: 1;
		position: absolute;
		right: 60px;
		bottom: 179px;
		@include breakpoint(xlarge down){
			right: 40px;
		    bottom: 124px;
			width: 87px;
		}
		@include breakpoint(medium down){
			display: none;
		}
	}
	.cloud-4{
		z-index: 1;
		position: absolute;
		right: 3px;
		bottom: 176px;
		@include breakpoint(xlarge down){
			right: 1px;
			bottom: 125px;
			width: 66px;
		}
		@include breakpoint(medium down){
			display: none;
		}
	}
	.cloud-5{
		position: absolute;
		top: 260px;
	    left: 10px;
	    @include breakpoint(xlarge down){
	    	top: 160px;
    	    width: 91px;
	    }
	    @include breakpoint(medium down){
	    	top: auto;
    	    z-index: 1;
    	    bottom: 10.9%;
    	    left: -10%;
	    }
	}
}
.kaifeng-bot{
	position: relative;
	margin-bottom: 240px;
	@include breakpoint(xlarge down){
		margin-bottom: 148px;
	}
	@include breakpoint(medium down){
		margin-bottom: 84px;
	}
	.article-area{
		text-align: right;
		.content{
			font-family: $ch-family;
			font-weight: 600;
			font-size: 33px;
			letter-spacing: 5px;
			line-height: 1.5;
			color: $blue;
			margin-bottom: 63px;
			@include breakpoint(xlarge down){
				font-size: 22px;
			    letter-spacing: 4px;
			    margin-bottom: 45px;
			}
			@include breakpoint(medium down){
				font-size: 16px;
			    letter-spacing: 2px;
				text-align: center;
			    margin-bottom: 30px;
			}
		}
		.pic{
			@include breakpoint(xlarge down){
				img{max-width: 470px;}
			}
			@include breakpoint(medium down){
				margin-right: calc(var(--mobileSpace) * -1);
				img{max-width: 220px;}
			}
		}
	}
	.deco{
		position: absolute;
		left: -20px;
	    bottom: -180px;
	    @include breakpoint(xlarge down){
	    	left: -12px;
	    	bottom: -120px;
	    	width: 596px;
	    }
	    @include breakpoint(medium down){
	    	left: -62px;
    	    bottom: -55px;
    	    width: 246px;
	    }
	}
}
.kaifeng-about-bot{
	@include breakpoint(medium down){
		padding: 0 var(--mobileSpace);
	}
}
.mobile-indexnav-wrap{
	// background: linear-gradient(to bottom, transparent 0%, rgba(#fff, 0.7) 40%, rgba(#fff, 1) 88%);
	padding: 20px 40px;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	.gradient{
		position: absolute;
		left: 0;
		bottom: -44px;
		>img{
			width: 120%;
			max-width: initial;
		}
	}
}
.mobile-indexnav-container{
	font-family: $ch-family;
	font-weight: 700;
	font-size: 13px;
	letter-spacing: 2px;
	color: $blue;
    white-space: nowrap;
    transform: translateX(-333.2%);
	li{
		writing-mode: vertical-lr;
		display: inline-block;
		vertical-align: bottom;
		width: 45.8%;
	}
}
.mobile-topmenu-footer{
	@extend .footerWrap;
	padding: 0;
    margin-top: 70px;
    .bot-area{
    	padding-bottom: 0;
    	margin-bottom: 60px;
    	border-bottom: none;
    }
}